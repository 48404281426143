import firebase from '../config/fbConfig'

const db = firebase.firestore()


export function getUserData(uid){
  return db.collection('users').get(uid)
  .then((snapshot)=>{
    return snapshot.docs[0].data()
  }).catch(err=>{
    console.log('error finding user with ID: ' + uid)
    return err
  })
}

// gets time_data collection from firebase, 
// stores as dict with key=time_id, value=time_obj
export function getTimeDataAsDict(){
  let output = {}
  return db.collection('time_data').get()
    .then((snapshot)=>{
      snapshot.docs.forEach(doc => {
        let data = doc.data()
        output[data.time_id] = data
      })
    }).then(() => {
      return output
    })
}

// gets day_time_data collection from firebase, 
// stores as dict with key=day, value=[time_ids]
export function getDayTimeDataAsDict(){
  let output;
  return db.collection('day_time_data').get()
    .then((snapshot)=>{
      snapshot.docs.forEach(doc => {
        output = doc.data()
      })
    }).then(() => {
      return output
    })
}

// gets church_data collection from firebase, 
// stores as dict with key=church_id, value=church_obj
export function getChurchDataAsDict(){
  let output = {};

  return db.collection('church_data')
    .get()
    .then((snapshot) => {
        snapshot.docs.forEach((doc)=>{
            output[doc.data().church_id] = doc.data()
        })
    }).then(()=>{
      return output
    })
}






// export function getTimeObjsByDay(day){
//     let output = []
//     return db.collection('day_time_data').get()
//     .then((snapshot)=>{
//         snapshot.docs.forEach(doc => {
//             let dayObj = doc.data()
//             // console.log(dayObj)
//             output = dayObj[day]
//         })
//     }).then(() => {
//         return output 
//     })
// }

export function getTimeById(id){
  let output = {}
  return db.collection('time_data').get()
    .then((snapshot)=>{
      snapshot.docs.forEach(doc => {
        let data = doc.data()
        // console.log(data.time_id)
        if (data.time_id === id){
          output = data
        }
      })
    }).then(() => {
      return output
    })
}

export function getChurchById(id){
  let output = {}

  return db.collection('church_data')
    .get()
    .then((snapshot)=>{
      snapshot.forEach(doc => {
        let data = doc.data().church_data
        if (data.church_id === id){
          output = data
        }
      })
    })
    .then(() => {
      return output
    })
}

export function getTimesByChurch(church_id){
  let output = {}
  return getChurchById(church_id).then((data)=>{
    output = data
  }).then(()=>{
    return output.times
  })
}

// export function getAllTimesByDay(day){
//     let output = []
//     return db.collection('day_time_data').get()
//     .then((snapshot)=>{
//         snapshot.docs.forEach(doc => {
//             let dayObj = doc.data()
//             // console.log(dayObj)
//             output = dayObj
//         })
//     }).then(() => {
//         return output 
//     })
// }

// TEST
// export function getAllTimesAndChurchesByDay(day){
//     let time_obj_promises;
//     return getTimeObjsByDay(day).then((data)=>{
//         time_obj_promises = data
//     }).then(()=>{
//         return Promise.all(time_obj_promises).then((vals)=>{
//             vals.map((time_obj) =>{
//                 getChurchById(time_obj.church_id).then(church_obj=>{
//                     time_obj['church_data'] = church_obj
//                 })
//             })
//             return vals
//         })
//     })
// }

export function getAllChurches(){
  let output = {};

  return db.collection('church_data')
    .get()
    .then((snapshot) => {
        snapshot.docs.forEach((doc)=>{
            output[doc.data().church_id] = doc.data()
        })
    }).then(()=>{
      return output
    })
}

export function getAllTimes() {
  let output = {};

  return db.collection('day_time_data')
    .get()
    .then((snapshot) => {
      snapshot.docs.forEach((doc)=>{
        output[doc.id] = doc.data()
      })
    }).then(()=>{
      return output
    })
}

export function makeTestTimes() {
  let c = getAllTimes();
  c.then((x) => {
    for( let q in x ) {
      let time = x[q];
      db.collection("two_time_data").doc(time.time_id).set({
        ...time,
        approved: false
      })
        .then(function() {
          console.log("Document successfully written!");
        })
        .catch(function(error) {
          console.error("Error writing document: ", error);
        });
    }
  });
}

export function approveTime(time) {
  db.collection("two_time_data").doc(time.time_id).set({
    ...time,
    approved: true
  })
    .then(function() {
      console.log("Document successfully written!");
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
    });
}


export function getDataTest(){
  let output = {};

  return db.collection('two_time_data')
    .get()
    .then((snapshot) => {
      snapshot.docs.forEach((doc)=>{
        output[doc.data().church_id] = doc.data()
      })
    }).then(()=>{
      return output
    })
}
