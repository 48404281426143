import React from 'react';
import { connect } from "react-redux"
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import prayer from '../../../../icons/pray_icon.png'
import {time_zone_options} from '../../../../utils/time_zone_options'
import {editUserAttr} from '../../../../actions/user&auth'
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  // input: {
  //   display: 'none',
  // },
}));

const handleAvatarSubmit = (e) =>{
  let file  = e.target.files[0]
  console.log(file)
}

function getFullTimeZone(tz){
  let output = tz
  time_zone_options.forEach((obj)=>{
    if (obj['value'] === tz) {
      output = obj['label']
    }
  })
  return output
}

const AccountProfile = props => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  const time_zone = user.time_zone ? getFullTimeZone(user.time_zone) : null
  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {user.firstName + " " + user.lastName}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {user.city}, {user.state}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {time_zone}
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
            src={prayer}
          />
        </div>
      </CardContent>
      <Divider />
      {/* <CardActions>
        <div>
          <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={handleAvatarSubmit}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span" >
            Upload {user.avatar ? 'new ' : ''}profile picture
          </Button>
        </label>
        </div>
        
      </CardActions> */}
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = dispatch => {
  return {
      editUserAttr: (uid, attribute, new_val) => dispatch(editUserAttr(uid, attribute, new_val)),
  }
}

export default connect(null, mapDispatchToProps)(AccountProfile);
