  
  const addDayTimeDataReducer = (state = {}, action) => {
    switch (action.type) {
      case 'EDIT_DAY_TIME_DATA_SUCCESS':
        console.log('edit day time data success');
        return state;
      case 'EDIT_DAY_TIME_DATA_ERROR':
        console.log('edit day time data error');
        return state;
      default:
        return state;
    }
  };
  
  export default addDayTimeDataReducer;