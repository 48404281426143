import React, { Component, Fragment } from 'react'
import {Link} from "react-router-dom";
import {Toolbar} from "@material-ui/core";

class ThankYou extends Component {
  render() {
    return (
      <div className="d-flex p-3 align-items-start flex-column" id="thankyou_container">
        <br />
        <br />
        <h3>Thank you!</h3>
        <p><div id="primary_span" className="">You are helping connect thousands of Catholics with God!</div>
          <br />
          Someone from our team will review your submission as soon as possible. We do this to make sure all mass streams in our database are real and formatted correctly. Please allow a few days to see your streams. Always feel free to email us with any questions!
        </p>
        <br />
        <span id="back_to_search"><Link to="/search">Find More Mass Times</Link></span>
      </div>
    )
  }
}

export default ThankYou;
