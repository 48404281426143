export const language_options = [ { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'latin', label: 'Latin' },
  { value: 'italian', label: 'Italian' },
  { value: 'mandarin', label: 'Mandarin' },
  { value: 'korean', label: 'Korean' },
  { value: 'vietnamese', label: 'Vietnamese' },
  { value: 'japanese', label: 'Japanese' },
  { value: 'portuguese', label: 'Portuguese' },
  { value: 'french', label: 'French' },
  { value: 'russian', label: 'Russian' },
  { value: 'german', label: 'German' },
  { value: 'arabic', label: 'Arabic' },
  { value: 'hindi', label: 'Hindi' },
  { value: 'bengali', label: 'Bengali' },
  { value: 'turkish', label: 'Turkish' },
  { value: 'polish', label: 'Polish' },
  { value: 'punjabi', label: 'Punjabi' },
  { value: 'ukrainian', label: 'Ukrainian' },
  { value: 'dutch', label: 'Dutch' },
  { value: 'thai', label: 'Thai' },
  { value: 'greek', label: 'Greek' },
  { value: 'hungarian', label: 'Hungarian' },
  { value: 'czech', label: 'Czech' },
  { value: 'javanese', label: 'Javanese' },
  { value: 'wu', label: 'Wu' },
  { value: 'malay', label: 'Malay' },
  { value: 'telugu', label: 'Telugu' },
  { value: 'marathi', label: 'Marathi' },
  { value: 'tamil', label: 'Tamil' },
  { value: 'urdu', label: 'Urdu' } ]

export default {language_options}