import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import * as serviceWorker from './serviceWorker';
import App from './App';

import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider, useSelector } from 'react-redux';
import reducers from './reducers';
import middleware from './middleware';

import thunk from 'redux-thunk'
import { createFirestoreInstance, getFirestore, reduxFirestore } from 'redux-firestore';
import {ReactReduxFirebaseProvider,getFirebase, isLoaded, reactReduxFirebase } from 'react-redux-firebase';
import firebase from './config/fbConfig'




const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true
}

const store = createStore(reducers,
  compose(
    middleware,
    reduxFirestore(firebase),
    // reactReduxFirebase(firebase, rrfConfig)
  ),
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

// function AuthIsLoaded({ children }) {
//   const auth = useSelector(state => state.firebase.auth)
//   if (!isLoaded(auth)) return <div><h5 className="center">Loading Page...</h5></div>;
//       return children
// }

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>, document.getElementById('root'));

serviceWorker.unregister();
