const editTimeDataAttrReducer = (state = {}, action) => {
    switch (action.type) {
      case 'EDIT_TIME_DATA_ATTR_SUCCESS':
        console.log('edit time data attribute success');
        return state;
      case 'EDIT_TIME_DATA_ATTR_ERROR':
        console.log('edit time data attribute error');
        return state;
      default:
        return state;
    }
  };
    
  export default editTimeDataAttrReducer;