export const diocese_options = [
	{
		"value": "Archdiocese of Atlanta",
		"label": "Archdiocese of Atlanta"
	},
	{
		"value": "Archdiocese of Anchorage",
		"label": "Archdiocese of Anchorage"
	},
	{
		"value": "Archdiocese of Baltimore",
		"label": "Archdiocese of Baltimore"
	},
	{
		"value": "Archdiocese of Boston",
		"label": "Archdiocese of Boston"
	},
	{
		"value": "Archdiocese of Chicago",
		"label": "Archdiocese of Chicago"
	},
	{
		"value": "Archdiocese of Cincinnati",
		"label": "Archdiocese of Cincinnati"
	},
	{
		"value": "Archdiocese of Detroit",
		"label": "Archdiocese of Detroit"
	},
	{
		"value": "Archdiocese of Denver",
		"label": "Archdiocese of Denver"
	},
	{
		"value": "Archdiocese of Dubuque",
		"label": "Archdiocese of Dubuque"
	},
	{
		"value": "Archdiocese of Galveston-Houston",
		"label": "Archdiocese of Galveston-Houston"
	},
	{
		"value": "Archdiocese of Hartford",
		"label": "Archdiocese of Hartford"
	},
	{
		"value": "Archdiocese of Indianapolis",
		"label": "Archdiocese of Indianapolis"
	},
	{
		"value": "Archdiocese of Kansas City",
		"label": "Archdiocese of Kansas City"
	},
	{
		"value": "Archdiocese of Los Angeles",
		"label": "Archdiocese of Los Angeles"
	},
	{
		"value": "Archdiocese of Louisville",
		"label": "Archdiocese of Louisville"
	},
	{
		"value": "Archdiocese of Miami",
		"label": "Archdiocese of Miami"
	},
	{
		"value": "Archdiocese of Milwaukee",
		"label": "Archdiocese of Milwaukee"
	},
	{
		"value": "Archdiocese of Minneapolis-St. Paul",
		"label": "Archdiocese of Minneapolis-St. Paul"
	},
	{
		"value": "Archdiocese of Mobile",
		"label": "Archdiocese of Mobile"
	},
	{
		"value": "Archdiocese of Newark",
		"label": "Archdiocese of Newark"
	},
	{
		"value": "Archdiocese of New Orleans",
		"label": "Archdiocese of New Orleans"
	},
	{
		"value": "Archdiocese of New York",
		"label": "Archdiocese of New York"
	},
	{
		"value": "Archdiocese of Oklahoma City",
		"label": "Archdiocese of Oklahoma City"
	},
	{
		"value": "Archdiocese of Omaha",
		"label": "Archdiocese of Omaha"
	},
	{
		"value": "Archdiocese of Philadelphia",
		"label": "Archdiocese of Philadelphia"
	},
	{
		"value": "Archdiocese of Portland (OR)",
		"label": "Archdiocese of Portland (OR)"
	},
	{
		"value": "Archdiocese of San Antonio",
		"label": "Archdiocese of San Antonio"
	},
	{
		"value": "Archdiocese of San Francisco",
		"label": "Archdiocese of San Francisco"
	},
	{
		"value": "Archdiocese of Santa Fe",
		"label": "Archdiocese of Santa Fe"
	},
	{
		"value": "Archdiocese of Seattle",
		"label": "Archdiocese of Seattle"
	},
	{
		"value": "Archdiocese of St. Louis",
		"label": "Archdiocese of St. Louis"
	},
	{
		"value": "Archdiocese of Washington DC",
		"label": "Archdiocese of Washington DC"
	},
	{
		"value": "Archdiocese for Military Services",
		"label": "Archdiocese for Military Services"
	},
	{
		"value": "Diocese of Albany",
		"label": "Diocese of Albany"
	},
	{
		"value": "Diocese of Alexandria (LA)",
		"label": "Diocese of Alexandria (LA)"
	},
	{
		"value": "Diocese of Allentown",
		"label": "Diocese of Allentown"
	},
	{
		"value": "Diocese of Altoona-Johnston",
		"label": "Diocese of Altoona-Johnston"
	},
	{
		"value": "Diocese of Amarillo",
		"label": "Diocese of Amarillo"
	},
	{
		"value": "Diocese of Arlington",
		"label": "Diocese of Arlington"
	},
	{
		"value": "Diocese of Austin",
		"label": "Diocese of Austin"
	},
	{
		"value": "Diocese of Baker",
		"label": "Diocese of Baker"
	},
	{
		"value": "Diocese of Baton Rouge",
		"label": "Diocese of Baton Rouge"
	},
	{
		"value": "Diocese of Beaumont",
		"label": "Diocese of Beaumont"
	},
	{
		"value": "Diocese of Belleville",
		"label": "Diocese of Belleville"
	},
	{
		"value": "Diocese of Biloxi",
		"label": "Diocese of Biloxi"
	},
	{
		"value": "Diocese of Birmingham",
		"label": "Diocese of Birmingham"
	},
	{
		"value": "Diocese of Bismarck",
		"label": "Diocese of Bismarck"
	},
	{
		"value": "Diocese of Boise",
		"label": "Diocese of Boise"
	},
	{
		"value": "Diocese of Brooklyn",
		"label": "Diocese of Brooklyn"
	},
	{
		"value": "Diocese of Bridgeport",
		"label": "Diocese of Bridgeport"
	},
	{
		"value": "Diocese of Brownsville",
		"label": "Diocese of Brownsville"
	},
	{
		"value": "Diocese of Buffalo",
		"label": "Diocese of Buffalo"
	},
	{
		"value": "Diocese of Burlington",
		"label": "Diocese of Burlington"
	},
	{
		"value": "Diocese of Camden",
		"label": "Diocese of Camden"
	},
	{
		"value": "Diocese of Charleston",
		"label": "Diocese of Charleston"
	},
	{
		"value": "Diocese of Charlotte",
		"label": "Diocese of Charlotte"
	},
	{
		"value": "Diocese of Cheyenne",
		"label": "Diocese of Cheyenne"
	},
	{
		"value": "Diocese of Cleveland",
		"label": "Diocese of Cleveland"
	},
	{
		"value": "Diocese of Colorado Springs",
		"label": "Diocese of Colorado Springs"
	},
	{
		"value": "Diocese of Columbus",
		"label": "Diocese of Columbus"
	},
	{
		"value": "Diocese of Corpus Christi",
		"label": "Diocese of Corpus Christi"
	},
	{
		"value": "Diocese of Covington",
		"label": "Diocese of Covington"
	},
	{
		"value": "Diocese of Crookston",
		"label": "Diocese of Crookston"
	},
	{
		"value": "Diocese of Dallas",
		"label": "Diocese of Dallas"
	},
	{
		"value": "Diocese of Davenport",
		"label": "Diocese of Davenport"
	},
	{
		"value": "Diocese of Des Moines",
		"label": "Diocese of Des Moines"
	},
	{
		"value": "Diocese of Dodge City",
		"label": "Diocese of Dodge City"
	},
	{
		"value": "Diocese of Duluth",
		"label": "Diocese of Duluth"
	},
	{
		"value": "Diocese of El Paso",
		"label": "Diocese of El Paso"
	},
	{
		"value": "Diocese of Erie",
		"label": "Diocese of Erie"
	},
	{
		"value": "Diocese of Evansville",
		"label": "Diocese of Evansville"
	},
	{
		"value": "Diocese of Fall River",
		"label": "Diocese of Fall River"
	},
	{
		"value": "Diocese of Fairbanks",
		"label": "Diocese of Fairbanks"
	},
	{
		"value": "Diocese of Fargo",
		"label": "Diocese of Fargo"
	},
	{
		"value": "Diocese of Fresno",
		"label": "Diocese of Fresno"
	},
	{
		"value": "Diocese of Ft. Wayne-South Bend",
		"label": "Diocese of Ft. Wayne-South Bend"
	},
	{
		"value": "Diocese of Ft. Worth*",
		"label": "Diocese of Ft. Worth*"
	},
	{
		"value": "Diocese of Gallup",
		"label": "Diocese of Gallup"
	},
	{
		"value": "Diocese of Gary",
		"label": "Diocese of Gary"
	},
	{
		"value": "Diocese of Gaylord",
		"label": "Diocese of Gaylord"
	},
	{
		"value": "Diocese of Grand Island",
		"label": "Diocese of Grand Island"
	},
	{
		"value": "Diocese of Grand Rapids",
		"label": "Diocese of Grand Rapids"
	},
	{
		"value": "Diocese of Great Falls-Billings",
		"label": "Diocese of Great Falls-Billings"
	},
	{
		"value": "Diocese of Green Bay",
		"label": "Diocese of Green Bay"
	},
	{
		"value": "Diocese of Greensburg",
		"label": "Diocese of Greensburg"
	},
	{
		"value": "Diocese of Harrisburg",
		"label": "Diocese of Harrisburg"
	},
	{
		"value": "Diocese of Helena",
		"label": "Diocese of Helena"
	},
	{
		"value": "Diocese of Honolulu",
		"label": "Diocese of Honolulu"
	},
	{
		"value": "Diocese of Houma-Thibodaux",
		"label": "Diocese of Houma-Thibodaux"
	},
	{
		"value": "Diocese of Jackson",
		"label": "Diocese of Jackson"
	},
	{
		"value": "Diocese of Jefferson City",
		"label": "Diocese of Jefferson City"
	},
	{
		"value": "Diocese of Joliet",
		"label": "Diocese of Joliet"
	},
	{
		"value": "Diocese of Juneau",
		"label": "Diocese of Juneau"
	},
	{
		"value": "Diocese of Kalamazoo",
		"label": "Diocese of Kalamazoo"
	},
	{
		"value": "Diocese of Kansas City-St. Joseph",
		"label": "Diocese of Kansas City-St. Joseph"
	},
	{
		"value": "Diocese of Knoxville",
		"label": "Diocese of Knoxville"
	},
	{
		"value": "Diocese of Lacrosse",
		"label": "Diocese of Lacrosse"
	},
	{
		"value": "Diocese of Lafayette (IN)",
		"label": "Diocese of Lafayette (IN)"
	},
	{
		"value": "Diocese of Lafayette (LA)",
		"label": "Diocese of Lafayette (LA)"
	},
	{
		"value": "Diocese of Laredo",
		"label": "Diocese of Laredo"
	},
	{
		"value": "Diocese of Lake Charles",
		"label": "Diocese of Lake Charles"
	},
	{
		"value": "Diocese of Lansing",
		"label": "Diocese of Lansing"
	},
	{
		"value": "Diocese of Las Cruces",
		"label": "Diocese of Las Cruces"
	},
	{
		"value": "Diocese of Las Vegas",
		"label": "Diocese of Las Vegas"
	},
	{
		"value": "Diocese of Lexington",
		"label": "Diocese of Lexington"
	},
	{
		"value": "Diocese of Little Rock",
		"label": "Diocese of Little Rock"
	},
	{
		"value": "Diocese of Lincoln",
		"label": "Diocese of Lincoln"
	},
	{
		"value": "Diocese of Lubbock",
		"label": "Diocese of Lubbock"
	},
	{
		"value": "Diocese of Madison",
		"label": "Diocese of Madison"
	},
	{
		"value": "Diocese of Manchester",
		"label": "Diocese of Manchester"
	},
	{
		"value": "Diocese of Marquette",
		"label": "Diocese of Marquette"
	},
	{
		"value": "Diocese of Memphis",
		"label": "Diocese of Memphis"
	},
	{
		"value": "Diocese of Metuchen",
		"label": "Diocese of Metuchen"
	},
	{
		"value": "Diocese of Monterey",
		"label": "Diocese of Monterey"
	},
	{
		"value": "Diocese of Nashville",
		"label": "Diocese of Nashville"
	},
	{
		"value": "Diocese of New Ulm",
		"label": "Diocese of New Ulm"
	},
	{
		"value": "Diocese of Norwich",
		"label": "Diocese of Norwich"
	},
	{
		"value": "Diocese of Oakland",
		"label": "Diocese of Oakland"
	},
	{
		"value": "Diocese of Orlando",
		"label": "Diocese of Orlando"
	},
	{
		"value": "Diocese of Palm Beach",
		"label": "Diocese of Palm Beach"
	},
	{
		"value": "Diocese of Paterson",
		"label": "Diocese of Paterson"
	},
	{
		"value": "Diocese of Peoria",
		"label": "Diocese of Peoria"
	},
	{
		"value": "Diocese of Phoenix",
		"label": "Diocese of Phoenix"
	},
	{
		"value": "Diocese of Pittsburgh",
		"label": "Diocese of Pittsburgh"
	},
	{
		"value": "Diocese of Porland (ME)",
		"label": "Diocese of Porland (ME)"
	},
	{
		"value": "Diocese of Providence",
		"label": "Diocese of Providence"
	},
	{
		"value": "Diocese of Pueblo",
		"label": "Diocese of Pueblo"
	},
	{
		"value": "Diocese of Ogdensberg",
		"label": "Diocese of Ogdensberg"
	},
	{
		"value": "Diocese of Orange",
		"label": "Diocese of Orange"
	},
	{
		"value": "Diocese of Owensboro",
		"label": "Diocese of Owensboro"
	},
	{
		"value": "Diocese of Pennsacola-Tallahassee",
		"label": "Diocese of Pennsacola-Tallahassee"
	},
	{
		"value": "Diocese of Raleigh",
		"label": "Diocese of Raleigh"
	},
	{
		"value": "Diocese of Rapid City",
		"label": "Diocese of Rapid City"
	},
	{
		"value": "Diocese of Reno",
		"label": "Diocese of Reno"
	},
	{
		"value": "Diocese of Richmond",
		"label": "Diocese of Richmond"
	},
	{
		"value": "Diocese of Rochester",
		"label": "Diocese of Rochester"
	},
	{
		"value": "Diocese of Rockford",
		"label": "Diocese of Rockford"
	},
	{
		"value": "Diocese of Rockville Centre",
		"label": "Diocese of Rockville Centre"
	},
	{
		"value": "Diocese of Sacramento",
		"label": "Diocese of Sacramento"
	},
	{
		"value": "Diocese of Saginaw",
		"label": "Diocese of Saginaw"
	},
	{
		"value": "Diocese of Salina",
		"label": "Diocese of Salina"
	},
	{
		"value": "Diocese of Salt Lake City",
		"label": "Diocese of Salt Lake City"
	},
	{
		"value": "Diocese of San Angelo",
		"label": "Diocese of San Angelo"
	},
	{
		"value": "Diocese of San Bernardino",
		"label": "Diocese of San Bernardino"
	},
	{
		"value": "Diocese of San Diego",
		"label": "Diocese of San Diego"
	},
	{
		"value": "Diocese of San Jose",
		"label": "Diocese of San Jose"
	},
	{
		"value": "Diocese of Santa Rosa",
		"label": "Diocese of Santa Rosa"
	},
	{
		"value": "Diocese of Savannah",
		"label": "Diocese of Savannah"
	},
	{
		"value": "Diocese of Scranton",
		"label": "Diocese of Scranton"
	},
	{
		"value": "Diocese of Shreveport",
		"label": "Diocese of Shreveport"
	},
	{
		"value": "Diocese of Sioux City",
		"label": "Diocese of Sioux City"
	},
	{
		"value": "Diocese of Sioux Falls",
		"label": "Diocese of Sioux Falls"
	},
	{
		"value": "Diocese of Spokane",
		"label": "Diocese of Spokane"
	},
	{
		"value": "Diocese of Springfield (IL)",
		"label": "Diocese of Springfield (IL)"
	},
	{
		"value": "Diocese of Springfield (MA)",
		"label": "Diocese of Springfield (MA)"
	},
	{
		"value": "Diocese of Springfield-Cape Girardeau",
		"label": "Diocese of Springfield-Cape Girardeau"
	},
	{
		"value": "Diocese of St. Augustine",
		"label": "Diocese of St. Augustine"
	},
	{
		"value": "Diocese of St. Cloud",
		"label": "Diocese of St. Cloud"
	},
	{
		"value": "Diocese of St. Petersburg",
		"label": "Diocese of St. Petersburg"
	},
	{
		"value": "Diocese of Steubenville",
		"label": "Diocese of Steubenville"
	},
	{
		"value": "Diocese of Stockton",
		"label": "Diocese of Stockton"
	},
	{
		"value": "Diocese of Superior",
		"label": "Diocese of Superior"
	},
	{
		"value": "Diocese of Syracuse",
		"label": "Diocese of Syracuse"
	},
	{
		"value": "Diocese of Toledo",
		"label": "Diocese of Toledo"
	},
	{
		"value": "Diocese of Trenton",
		"label": "Diocese of Trenton"
	},
	{
		"value": "Diocese of Tucson",
		"label": "Diocese of Tucson"
	},
	{
		"value": "Diocese of Tulsa",
		"label": "Diocese of Tulsa"
	},
	{
		"value": "Diocese of Tyler",
		"label": "Diocese of Tyler"
	},
	{
		"value": "Diocese of Venice",
		"label": "Diocese of Venice"
	},
	{
		"value": "Diocese of Victoria",
		"label": "Diocese of Victoria"
	},
	{
		"value": "Diocese of Wheeling-Charleston",
		"label": "Diocese of Wheeling-Charleston"
	},
	{
		"value": "Diocese of Wichita",
		"label": "Diocese of Wichita"
	},
	{
		"value": "Diocese of Wilmington",
		"label": "Diocese of Wilmington"
	},
	{
		"value": "Diocese of Winona-Rochester",
		"label": "Diocese of Winona-Rochester"
	},
	{
		"value": "Diocese of Worcester",
		"label": "Diocese of Worcester"
	},
	{
		"value": "Diocese of Yakima",
		"label": "Diocese of Yakima"
	},
	{
		"value": "Diocese of Youngstown",
		"label": "Diocese of Youngstown"
	}
]

export default { diocese_options }