import React, { Component, Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { connect } from "react-redux"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { StatusBullet } from 'components';
import {
  handleSortByChurchName,
  handleSortByTimezone,
  handleSortByCity,
  handleSortByState, handleSortByTime
} from "../../../../actions/churchtimes";
import {handleViewLivestreamClick} from "../../../../actions/clickEvents";

import {getAllTimes, getAllTimesByDay} from "../../../../utils/fb_api";
import ChurchTableCells from "./ChurchTableCells";

class LatestOrders extends Component {

  state = {
    timezoneToggleAsc: false,
    churchnameToggleAsc: false,
    cityToggleAsc: false,
    stateToggleAsc: false,
    timeToggleAsc: false
  }

  handleTimezoneToggle = () => {
    let toggledTimezone = !this.state.timezoneToggleAsc;
    this.props.dispatch(handleSortByTimezone(toggledTimezone));
    this.setState({
      timezoneToggleAsc: toggledTimezone
    });
  };

  handleChurchNameToggle = () => {
    let toggledChurchName = !this.state.churchnameToggleAsc;
    this.props.dispatch(handleSortByChurchName(toggledChurchName));
    this.setState({
      churchnameToggleAsc: toggledChurchName
    });
  };

  handleCityToggle = () => {
    let toggled = !this.state.cityToggleAsc;
    this.props.dispatch(handleSortByCity(toggled));
    this.setState({
      cityToggleAsc: toggled
    });
  };

  handleStateToggle = () => {
    let toggled = !this.state.stateToggleAsc;
    this.props.dispatch(handleSortByState(toggled));
    this.setState({
      stateToggleAsc: toggled
    });
  };

  handleTimeToggle = () => {
    let toggled = !this.state.timeToggleAsc;
    this.props.dispatch(handleSortByTime(toggled));
    this.setState({
      timeToggleAsc: toggled
    });
  };

  render() {
    const { churchtimes, times_count, day, daySelected } = this.props;
    const { timezoneToggleAsc, churchnameToggleAsc, cityToggleAsc, stateToggleAsc, timeToggleAsc } = this.state;

    let day_selected = daySelected ? `in ${daySelected.label}` : "";
    let table_prompt = `${day} Mass Live Streams ${day_selected}`;
    let table_subheader = `${times_count} results found`;

    return (
      <Fragment>
      <Card
        id="church_table"
      >
        <CardHeader
          title={table_prompt}
          subheader={table_subheader}
        />
        <Divider />
        <CardContent className="tablecontent">
          <PerfectScrollbar>
            <div className="tableinner">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Link</TableCell>
                    <TableCell>Day</TableCell>
                    <TableCell id="semiwide-column">
                      <div className="table_header_inner" onClick={this.handleTimeToggle}>
                        Time { timeToggleAsc ? <ExpandMoreIcon className="sort_icon"/> : <ExpandLessIcon className="sort_icon"/> }
                        </div>
                    </TableCell>
                    <TableCell className="timezone_column">
                      <div className="table_header_inner" onClick={this.handleTimezoneToggle}>
                        Timezone { timezoneToggleAsc ? <ExpandMoreIcon className="sort_icon"/> : <ExpandLessIcon className="sort_icon"/> }
                        </div>
                    </TableCell>
                    <TableCell id="church_name_column">
                      <div className="table_header_inner" onClick={this.handleChurchNameToggle}>
                        Church { churchnameToggleAsc ? <ExpandMoreIcon className="sort_icon"/> : <ExpandLessIcon className="sort_icon"/> }
                        </div>
                    </TableCell>
                    <TableCell id="semiwide_column">
                      <div className="table_header_inner" onClick={this.handleCityToggle}>
                        City { cityToggleAsc ? <ExpandMoreIcon className="sort_icon"/> : <ExpandLessIcon className="sort_icon"/> }
                        </div>
                    </TableCell>
                    <TableCell id="semiwide_column">
                      <div className="table_header_inner" onClick={this.handleStateToggle}>
                      State { stateToggleAsc ? <ExpandMoreIcon className="sort_icon"/> : <ExpandLessIcon className="sort_icon"/> }
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    churchtimes
                    .map((time) => {

                      if (time.approved) {
                        return (
                          <TableRow
                            hover
                            key={time.time_id}
                          >
                            <TableCell>
                              <div className="tablestatusContainer">
                                <a target="_blank" href={time.livestream_link}><div onClick={() => {
                                  handleViewLivestreamClick();
                                }}>Live Stream</div></a>
                              </div>
                            </TableCell>
                            <TableCell>{time.day.charAt(0).toUpperCase() + time.day.slice(1)}</TableCell>
                            <TableCell>{time.time}</TableCell>
                            <TableCell>{time.time_zone}</TableCell>
                            <ChurchTableCells church_id={time.church_id}/>
                          </TableRow>
                        )
                      }

                    })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
      </Fragment>
    )
  };
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

function mapStateToProps({ churchtimes, displayedTimes, datetime }) {

  let count = churchtimes ? churchtimes.flat().length : 0;

  return {
    churchtimes: displayedTimes,
    times_count: count,
    day: datetime.day ? datetime.day : ""
  }
}

export default connect(mapStateToProps)(LatestOrders);
