export const editChurchDataAttr = (church_id, attribute, new_val) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      //   const profile = getState().firebase.profile;
      //   const authorId = getState().firebase.auth.uid;
  
      firestore.collection('church_data').doc(church_id).update(
          {[attribute]: new_val}
      )
          .then(() => {
            dispatch({ type: 'EDIT_CHURCH_DATA_ATTR_SUCCESS' });
          }).catch(err => {
          dispatch({ type: 'EDIT_CHURCH_DATA_ATTR_ERROR' }, err);
        });
    }
  };
  
  export default editChurchDataAttr
  