export const SET_DISPLAYED_TIMES = "SET_DISPLAYED_TIMES";

export function handleSetDisplayedTimes() {
  return (dispatch, getState) => {
    const { churchtimes, pagination } = getState();
    dispatch(setDisplayedTimes(churchtimes.length > 0 ? churchtimes[pagination] : churchtimes))
  }
}

export function setDisplayedTimes(times) {
  return {
    type: SET_DISPLAYED_TIMES,
    times
  }
}

