import { SET_DAY_TIMES } from "../actions/dayTimes";

export default function dayTimes(state = {}, action) {

  switch(action.type) {
    case SET_DAY_TIMES:
      return action.dayTimes;
    default:
      return state;
  }
}
