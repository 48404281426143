import React, { useState } from 'react';
import { connect } from "react-redux"
import {time_zone_options} from '../../../../utils/time_zone_options'
import {states} from '../../../../utils/states'
import {editUserAttr, updateUserEmail, updateUserPassword, reauthenticateUser} from '../../../../actions/user&auth'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, user, auth, ...rest } = props;

  var reauthenticated = false;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleCloseAndSubmit = () => {
    credentials.password = values.password
    handleClose()
    handleSubmit()
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var credentials = {
    'email': auth.email
  }

  const [values, setValues] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: auth.email,
    password: '',
    password_new: '',
    password_confirm: '',
    phone: user.phone,
    state: user.state,
    zipcode: user.zipcode,
    time_zone: user.time_zone,
    city: user.city
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = () => {
    props.reauthenticateUser(credentials).then(result=>{
      reauthenticated = result
      if (!reauthenticated){
        alert('Incorrect password, please try again')
        handleClickOpen()
      } else {
          let changed = false
          for (let key in values){
            if (key==='email' && values[key] !== auth.email){
              if (values[key].includes('@')){
                changed = true
                props.updateUserEmail(values[key], credentials)
              } else {
                alert('Email is not valid')
                return
              }
            }
            if (key==='password_new' && values['password_confirm'].length!==0){
              if (values[key]===values['password_confirm']){
                changed = true
                props.updateUserPassword(values[key], credentials)
              } else{
                alert('Password fields do not match')
                return
              }
            }
            if (user[key] !== undefined && values[key] !== user[key]){
              changed = true
              props.editUserAttr(auth.uid, key, values[key])
            }
          }
          if (changed) alert('User Info Successfully Updated')
          else alert('Please modify fields before submitting')
      }
    })
    

    

  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="Edit your information"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                type="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Use 10 numbers, no hyphens"
                label="Phone Number"
                margin="dense"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="New Password"
                margin="dense"
                name="password_new"
                onChange={handleChange}
                type="password"
                value={values.password_new}
                variant="outlined"
              />
            </Grid>
              <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Confirm New Password"
                margin="dense"
                name="password_confirm"
                onChange={handleChange}
                type='password'
                value={values.password_confirm}
                variant="outlined"
              />
            </Grid>
          
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="City"
                margin="dense"
                name="city"
                onChange={handleChange}
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="State"
                margin="dense"
                name="state"
                onChange={handleChange}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.state}
                variant="outlined"
              >
                {states.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Zip Code"
                margin="dense"
                name="zipcode"
                onChange={handleChange}
                value={values.zipcode}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Time Zone"
                margin="dense"
                name="time_zone"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.time_zone}
                variant="outlined"
              >
                {time_zone_options.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickOpen}
          >
            Save details
          </Button>
        </CardActions>
      </form>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your current password to save your new details
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            onChange={handleChange}
            id="password"
            name="password"
            label="Current Password"
            type="password"
            value={values.password}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseAndSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = dispatch => {
  return {
      updateUserEmail: (newEmail) => dispatch(updateUserEmail(newEmail)),
      updateUserPassword: (newPassword) => dispatch(updateUserPassword(newPassword)),
      editUserAttr: (uid, attribute, newVal) => dispatch(editUserAttr(uid, attribute, newVal)),
      reauthenticateUser: (credentials) => dispatch(reauthenticateUser(credentials))
  }
}
export default connect(null, mapDispatchToProps)(AccountDetails);
