import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Avatar } from '@material-ui/core';
import dove from '../../../../icons/dove.png';
import pray from '../../../../icons/pray_icon_white.png';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux'



const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  }
}));

const Topbar = props => {
  const {auth, className, ...rest } = props;

  const classes = useStyles();

  const userLinks = auth.uid 
    ? <Link to={"/user/"+auth.uid}>
          <img src={pray} id="dove_icon" alt='Choose Location Icon'/>
      </Link>
    : <div>
      {/*<span id="add_here"><Link to="/signin">Sign In</Link></span>*/}
      {/*<span id="add_here"><Link to="/signup">Sign Up</Link></span>*/}
      </div>
    

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <div className="d-flex justify-content-center align-items-center">
            <img src={dove} id="dove_icon" alt='Choose Location Icon'/>
            <span id="nav_title">With Your Spirit</span>
          </div>
        </RouterLink>
        <div className={classes.flexGrow} />
        {userLinks}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func
  }
}

export default connect(mapStateToProps, null)(Topbar);
