import { getTimeDataAsDict } from '../utils/fb_api';
import { handleSetDayTimes } from "./dayTimes";
import {setAppLoaded} from "./appLoaded";
import {handleGetChurchTimesByDay} from "./churchtimes";

export const SET_ALL_TIMES = "SET_ALL_TIMES";

export function handleSetAllTimes() {
  return (dispatch, getState) => {
    getTimeDataAsDict()
      .then(times => dispatch(setAllTimes(times)))
      .then(() => dispatch(handleSetDayTimes()))
    // getAllTimesByDay()
    //   .then(times => dispatch(setAllTimes((times))))
    //   .then(() => dispatch(setAppLoaded(true)))
    //   .then(() => dispatch(handleGetChurchTimesByDay("sunday", "")))
  }
}

function setAllTimes(times) {
  return {
    type: SET_ALL_TIMES,
    times
  }
}

