import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import {Redirect} from 'react-router-dom'
import { connect } from 'react-redux';



import { AccountProfile, AccountDetails } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Account = ({auth, user, firebase,match}) => {
  const classes = useStyles();

  if (user.isEmpty){
    return <Redirect to={'/signin'} />
  }
  if (auth.uid !== match.params['id']){
    return <Redirect to={'/user/'+auth.uid} />
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <AccountProfile user={user}/>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          {user.church_admin_id ? 
          <a href={"/church/"+user.church_admin_id}>Your Church Profile</a>
          :
          null
          }
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <AccountDetails user={user} auth={auth} firebase={firebase}/>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps({firebase}) {
  return {
    auth: firebase.auth,
    user: firebase.profile,
    firebase
  }
}

export default connect(mapStateToProps)(Account);
