import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { addNewChurch } from '../../actions/addNewChurch'
import { v4 as uuidv4 } from 'uuid'
import addTimeData from '../../actions/addTimeData'
import addDayTimeData from '../../actions/addDayTimeData'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import {getAllChurches} from '../../utils/fb_api'

import { animateScroll as scroll } from 'react-scroll'


let my_time_data_list, my_church_data;

class NewChurchForm extends Component {
  // componentDidMount() {
  //   var churches 
  //   getAllChurches().then((result=>{
  //     churches = result
  //     for (let key in churches){
  //       this.props.addNewChurch(churches[key])
  //     }
  //   }))
    
  // }

    state = {
        currentStep: 1,
        stepContext: ['Enter Church Information', 'Enter Mass Information', 'Enter Parish Contact Information'],
        diocese:	'',
        church_name:	'',
        pastor_name:	'',
        city:	'',
        state:	'',
        address:	'',
        zipcode:	'',
        contact_name:	'',
        contact_title:	'',
        contact_number:	'',
        contact_email:	'',
        facebook:	'',
        youtube:	'',
        twitter:	'',
        instagram:	'',
        subscribed:	false,
        time_zone:  '',
        days: [],
        dayToTimeData: {
          
        }
    }

    handleChange = (e) => {
      
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleSelectChange = (n, v) => {
      if (n === 'days'){
        this.setState({
          ['dayToTimeData']: {}
        })
        this.setState({
          [n]: v
        })
      } else {
        this.setState({
          [n]: v['value']
        })
      }
    }

    handlePageTwoChange = (type, service, day, v) => {
      let dayToTimeData = this.state.dayToTimeData
      if (type == 'link'){
        dayToTimeData[day]['livestream_link']= v.target.value
      } else if (type == 'lang') {
        dayToTimeData[day][service]['language'] = v['value']
      } else if (type == 'time') {
        dayToTimeData[day][service]['time'] = v
      }
    }

    addExistingChurchFromOldData = (old_church_object) => {
      my_church_data = old_church_object
      my_time_data_list = this.getListTimeData(my_church_data)
      this.initializeTimes(my_church_data, my_time_data_list)
      let day_time = this.getDayTimeData(my_time_data_list)

      this.props.addNewChurch(my_church_data);
      this.props.addTimeData(my_time_data_list);
      this.props.editDayTimeData(day_time)
    }

    handleSubmit = (e) => {
        e.preventDefault();

        my_church_data = this.getChurchData()
        my_time_data_list = this.getListTimeData(my_church_data)
        this.initializeTimes(my_church_data, my_time_data_list)
        let day_time = this.getDayTimeData(my_time_data_list)
        
        this.props.addNewChurch(my_church_data);
        this.props.addTimeData(my_time_data_list);
        this.props.editDayTimeData(day_time)
        
        this.props.history.push('/thankyou');
    }

    getChurchData = () => {
        const {diocese, church_name, pastor_name,
          city, state, address, zipcode, contact_name, 
          contact_title, contact_number, contact_email,
          facebook, youtube, twitter, instagram, subscribed, 
          time_zone, days} = this.state;

        let newDays = []
        days.forEach(obj=>{
          newDays.push(obj.value)
        })

        let church_data = { 
          church_id: uuidv4(), diocese, church_name, pastor_name,
          city, state, address, zipcode, contact_name, 
          contact_title, contact_number, contact_email,
          facebook, youtube, twitter, instagram, subscribed, 
          time_zone, days: newDays,
          times: []
        }
        return church_data
    }

    getListTimeData = (church_data) => {
      let time_data_list = []
        for (let day in this.state.dayToTimeData){
          for (let i=1; i<4; i++){
            if (this.state.dayToTimeData[day]['service' + i]['time'] !== ""){
              let time_obj = this.state.dayToTimeData[day]['service' + i]['time']
              time_data_list.push(
                {
                  language: this.state.dayToTimeData[day]['service' + i]['language'],
                  time_id: uuidv4(),
                  church_id: church_data.church_id,
                  day,
                  time: time_obj.format("h:mm a"),
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  livestream_link: this.state.dayToTimeData[day]['livestream_link'],
                  time_zone: church_data.time_zone,
                  military: time_obj._d.toTimeString().slice(0,5),
                  approved: false
              }
              )
            }
          }
        }
        return time_data_list
    }

    initializeTimes = (church_data, time_data_list) => {
      let list = []
      time_data_list.forEach(e=>{
        list.push(e['time_id'])
      })
      church_data.times = list
    }

    getDayTimeData = (time_data_list) => {
      let obj = {}
      time_data_list.forEach(time_data=>{
        let key = time_data['day']
        if (obj[key] === undefined){
          obj[key] = []
        }
        obj[key].push(time_data.time_id)
      })
      return obj
    }

    _next = () => {
        let currentStep = this.state.currentStep
        currentStep = currentStep >= 2? 3: currentStep + 1
        this.setState({
          currentStep: currentStep
        }, () => {
          scroll.scrollToTop({
            duration: 350,
            delay: 0,
            smooth: true,
          });
      })
      }
        
    _prev = () => {
      let currentStep = this.state.currentStep

      currentStep = currentStep <= 1? 1: currentStep - 1
      this.setState({
        currentStep: currentStep
      }, () => {
        scroll.scrollToTop({
          duration: 350,
          delay: 0,
          smooth: true,
        });
      })
    }
    
    /*
    * the functions for our button
    */
    previousButton(){
      let currentStep = this.state.currentStep;
      if (currentStep !==1){
        return (
          <button
            className="btn btn-secondary" 
            type="button" onClick={this._prev}>
            Go back to step {--currentStep}
          </button>
        )
      }
      return null;
    }
    
    nextButton(dis){
      let currentStep = this.state.currentStep;
      if(currentStep <3){
        return (
          <Fragment>
            { dis ? <span id="primary_span">* Please fill out all fields above to continue</span> : ""}
            <button
              disabled={dis}
              className="btn form_button float-right"
              type="button" onClick={this._next}>
              Go to step {++currentStep}
            </button>
          </Fragment>
        )
      }
      return null;
    }

    

    render() {
      const { church_name, diocese, time_zone, state, city, address, zipcode, days, currentStep } = this.state;

      const readyForNext =
        (currentStep === 1 &&
          church_name.length > 0 &&
          diocese.length > 0 &&
          time_zone.length > 0 &&
          state.length > 0 &&
          city.length > 0 &&
          address.length > 0 &&
          zipcode.length > 0 &&
          days.length > 0)
        ||
        (currentStep === 2);
        return (
            <div id="form_container">
                    <h3 className="header_dashboard">Add Livestream Mass Times</h3>
              <span id="primary_span" className="step_text"><strong>Step {this.state.currentStep} of 3</strong> - {this.state.stepContext[this.state.currentStep-1]}</span>

                    <form onSubmit={this.handleSubmit}>
                    {/* 
                        render the form steps and pass required props in
                    */}
                        <Step1 
                        state={this.state}
                        handleChange={this.handleChange}
                        handleSelectChange={this.handleSelectChange}
                        previousButton={this.previousButton}
                        nextButton={this.nextButton}
                        />
                        <Step2 
                        state={this.state}
                        currentStep={this.state.currentStep}
                        handlePageTwoChange={this.handlePageTwoChange}
                        dayToTimeData={this.state.dayToTimeData}
                        days={this.state.days}
                        />
                        <Step3 
                        state={this.state}
                        currentStep={this.state.currentStep} 
                        handleChange={this.handleChange}
                        handleSelectChange={this.handleSelectChange}
                        />
                        {this.previousButton()}
                        {this.nextButton(!readyForNext)}

                    </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        addNewChurch: (church_data) => dispatch(addNewChurch(church_data)),
        addTimeData: (time_data) => dispatch(addTimeData(time_data)),
        editDayTimeData: (new_data) => dispatch(addDayTimeData(new_data))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(NewChurchForm)
