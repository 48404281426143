import {
  SET_DATE_TIME,
  SET_DAY,
  SET_TIME, TOGGLE_DAY
} from '../actions/datetime';

export default function datetime(state = {day: "Sunday"}, action) {
  switch(action.type) {
    case SET_DATE_TIME:
      return {
        ...state,
        days: action.day,
      };
    case SET_DAY:
      return {
        ...state,
        day: action.day
      };
    // case TOGGLE_DAY:
    //   return {
    //     ...state,
    //     days: state.days.includes(action.day) ? state.days.filter(d => d !== action.day) : state.days.concat([action.day])
    //   };
    default:
      return state;
  }
}
