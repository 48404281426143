import React, { Component, Fragment } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';

import { handleToggleDay } from '../../../../actions/datetime';
import { Card } from '@material-ui/core';

class DayPicker extends Component {

  handleChange = event => {
    this.props.dispatch(handleToggleDay(event.target.name))
  };

  render() {

    const { day } = this.props;

    return (
      <Fragment>
        <div id="search_prompt">
          <span id="primary_span">1. Select a day of the week</span>
        </div>
        <FormGroup row className="fade-in" id="day_picker_mini">

          <Card className={day === "Sunday" ? "check_active" : ""}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={day === "Sunday"}
                  onChange={this.handleChange}
                  name="Sunday"
                  color="primary"
                />
              }
              label="Sunday"
            />
          </Card>

          <Card className={day === "Saturday" ? "check_active" : ""}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={day === "Saturday"}
                  onChange={this.handleChange}
                  name="Saturday"
                  color="primary"
                />
              }
              label="Saturday"
            />
          </Card>

          <Card className={day === "Friday" ? "check_active" : ""}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={day === "Friday"}
                  onChange={this.handleChange}
                  name="Friday"
                  color="primary"
                />
              }
              label="Friday"
            />
          </Card>

          <Card className={day === "Thursday" ? "check_active" : ""}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={day === "Thursday"}
                  onChange={this.handleChange}
                  name="Thursday"
                  color="primary"
                />
              }
              label="Thursday"
            />
          </Card>

          <Card className={day === "Wednesday" ? "check_active" : ""}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={day === "Wednesday"}
                  onChange={this.handleChange}
                  name="Wednesday"
                  color="primary"
                />
              }
              label="Wednesday"
            />
          </Card>

          <Card className={day === "Tuesday" ? "check_active" : ""}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={day === "Tuesday"}
                  onChange={this.handleChange}
                  name="Tuesday"
                  color="primary"
                />
              }
              label="Tuesday"
            />
          </Card>

          <Card className={day === "Monday" ? "check_active" : ""}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={day === "Monday"}
                  onChange={this.handleChange}
                  name="Monday"
                  color="primary"
                />
              }
              label="Monday"
            />
          </Card>

        </FormGroup>
      </Fragment>
    )
  }
}

function mapStateToProps({ datetime }) {
  return {
    day: datetime.day ? datetime.day : ""
  }
}

export default connect(mapStateToProps)(DayPicker);
