export const time_zone_options = [
	{
		"label": "HST, Hawaii Standard Time",
		"value": "HST"
	},
	{
		"label": "AKDT, Alaska Daylight Time Anchorage",
		"value": "AKDT"
	},
	{
		"label": "PDT, Pacific Daylight Time",
		"value": "PDT"
	},
	{
		"label": "MST, Mountain Standard Time",
		"value": "MST"
	},
	{
		"label": "MDT, Mountain Daylight Time",
		"value": "MDT"
	},
	{
		"label": "CDT, Central Daylight Time",
		"value": "CDT"
	},
	{
		"label": "EDT, Eastern Daylight Time",
		"value": "EDT"
	}
]

export default { time_zone_options }