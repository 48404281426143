import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import SignIn from './auth/SignIn'
import SignUp from './auth/SignUp'

import {
  Dashboard as SearchView,
  ProductList as ProductListView,
  User as UserView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  Landing as LandingView,
  NotFound as NotFoundView,
  AddChurch as AddChurchView,
  Admin as Admin,
  Church as Church
} from './views';

import ThankYou from './views/NewChurchForm/ThankYou'

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={SearchView}
        exact
        layout={MainLayout}
        path="/search"
      />
      <RouteWithLayout
        component={AddChurchView}
        exact
        layout={MainLayout}
        path="/addchurch"
      />
      {/*<RouteWithLayout*/}
        {/*component={SignUp}*/}
        {/*exact*/}
        {/*layout={MainLayout}*/}
        {/*path="/signup"*/}
      {/*/>*/}
      {/*<RouteWithLayout*/}
        {/*component={SignUpView}*/}
        {/*exact*/}
        {/*layout={MainLayout}*/}
        {/*path="/signup2"*/}
      {/*/>*/}
      {/*<RouteWithLayout*/}
        {/*component={SignIn}*/}
        {/*exact*/}
        {/*layout={MainLayout}*/}
        {/*path="/signin"*/}
      {/*/>*/}
      <RouteWithLayout
        component={ThankYou}
        exact
        layout={MainLayout}
        path="/thankyou"
      />
      <RouteWithLayout
        component={Church}
        exact
        layout={MainLayout}
        path="/church/:id"
      />
       <RouteWithLayout
        component={Admin}
        exact
        layout={MainLayout}
        path="/admin"
      />
      {/* <RouteWithLayout
        component={UserView}
        exact
        layout={MainLayout}
        path="/users/:id"
      /> */}
      {/* <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      /> */}
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/user/:id"
      />
      {/* <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      /> */}
      <RouteWithLayout
        component={LandingView}
        exact
        layout={MinimalLayout}
        path="/"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
