import {
  SET_CHURCH_TIMES_BY_DAY,
  SET_CHURCH_TIMES_BY_SEARCH,
  SORT_BY_CHURCH_NAME,
  SORT_BY_TIMEZONE,
  SORT_BY_CITY,
  SORT_BY_STATE,
  SORT_BY_TIME,
  SET_CHURCH_TIMES
} from '../actions/churchtimes';


const list_size = 50;

function chunkArray(array, size) {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size)
    result.push(chunk)
  }
  return result
}

export default function churchtimes(state = [], action) {
  const { search, church_times, pagination } = action;

  switch(action.type) {
    case SET_CHURCH_TIMES_BY_DAY:

      if(search.length > 0) {
        const data = church_times.sort((a, b) => a.military > b.military).filter((c) => {
          if (c && action.allChurches[c.church_id]) {
            return action.allChurches[c.church_id].state.toLowerCase().includes(search.toLowerCase())
          }
        });

        const data_chunks = chunkArray(data, list_size);

        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      } else {
        const data = church_times.sort((a, b) => a.military > b.military);
        const data_chunks = chunkArray(data, list_size);

        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      }
    // case SET_CHURCH_TIMES_BY_SEARCH:
    //
    //   if(church_times.length === 0) {
    //     const data = church_times.sort((a, b) => a.military > b.military).filter((c) => c.church.state.toLowerCase().includes(search.toLowerCase()));
    //     const data_chunks = chunkArray(data, list_size);
    //
    //     return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
    //   } else {
    //     const data = [...state].filter((c) => c.church.state.toLowerCase().includes(search.toLowerCase()));
    //     const data_chunks = chunkArray(data, list_size);
    //
    //     return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
    //   }
    case SORT_BY_TIME:
      if(action.toggleAsc) {
        let d = state.flat().sort((a, b) => {
          if (a.military && b.military) {
            return a.military > b.military ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      } else {
        let d = state.flat().sort((a, b) => {
          if (a.military && b.military) {
            return b.military > a.military ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      }
    case SORT_BY_TIMEZONE:
      if(action.toggleAsc) {
        let d = state.flat().sort((a, b) => {
          if (a.time_zone && b.time_zone) {
            return a.time_zone > b.time_zone ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      } else {
        let d = state.flat().sort((a, b) => {
          if (a.time_zone && b.time_zone) {
            return a.time_zone < b.time_zone ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      }
    case SORT_BY_CHURCH_NAME:
      if(action.toggleAsc) {
        let d = state.flat().sort((a, b) => {
          if (action.allChurches[a.church_id] && action.allChurches[b.church_id]) {
            return action.allChurches[a.church_id].church_name > action.allChurches[b.church_id].church_name  ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      } else {
        let d = state.flat().sort((a, b) => {
          if (action.allChurches[a.church_id] && action.allChurches[b.church_id]) {
            return action.allChurches[a.church_id].church_name < action.allChurches[b.church_id].church_name ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      }
    case SORT_BY_CITY:
      if(action.toggleAsc) {
        let d = state.flat().sort((a, b) => {
          if (action.allChurches[a.church_id] && action.allChurches[b.church_id]) {
            return action.allChurches[a.church_id].city > action.allChurches[b.church_id].city ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      } else {
        let d = state.flat().sort((a, b) => {
          if (action.allChurches[a.church_id] && action.allChurches[b.church_id]) {
            return action.allChurches[a.church_id].city < action.allChurches[b.church_id].city ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      }
    case SORT_BY_STATE:
      if(action.toggleAsc) {
        let d = state.flat().sort((a, b) => {
          if (action.allChurches[a.church_id] && action.allChurches[b.church_id]) {
            return action.allChurches[a.church_id].state > action.allChurches[b.church_id].state ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      } else {
        let d = state.flat().sort((a, b) => {
          if (action.allChurches[a.church_id] && action.allChurches[b.church_id]) {
            return action.allChurches[a.church_id].state < action.allChurches[b.church_id].state ? -1 : 1;
          }
        });
        const data_chunks = chunkArray(d, list_size);
        return data_chunks[pagination] > 0 ? data_chunks[pagination] : data_chunks;
      }
    default:
      return state;
  }
}
