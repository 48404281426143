import thunk from 'redux-thunk';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';
import { applyMiddleware, compose } from "redux";
import firebase from '../config/fbConfig';


import logger from './logger'

const mw = [
    thunk.withExtraArgument({ getFirebase, getFirestore }),
  ];

// export default applyMiddleware(
//     logger,
//     compose(
//         applyMiddleware(
//           thunk.withExtraArgument({ getFirebase, getFirestore }),
//         ),  reduxFirestore(firebase)
//     )
//     // logger
// );

export default applyMiddleware(
  thunk.withExtraArgument({ getFirebase, getFirestore }),
  // logger
);
