import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import {
  TableCell, TableRow,
} from '@material-ui/core';


class ChurchTableCells extends Component {

  render() {
    const { church } = this.props;

    return (
      <Fragment>
        <TableCell><Link to={`/church/${church.church_id}`}>{church.church_name}</Link></TableCell>
        <TableCell>{church.city}</TableCell>
        <TableCell>{church.state}</TableCell>
      </Fragment>
    )
  }
}

function mapStateToProps({ allChurches }, { church_id } ) {
  // console.log(allChurches);
  // console.log(church_id);
  return {
    church: allChurches[church_id]
  }
}

export default connect(mapStateToProps)(ChurchTableCells)
