// for new times added on /church/:id pages

export const addTime = (time_obj) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      //   const profile = getState().firebase.profile;
      //   const authorId = getState().firebase.auth.uid;
  
      firestore.collection('time_data').doc(time_obj.time_id).set({
          ...time_obj
      })
        .then(()=>{
            firestore.collection('day_time_data').doc('dtd_doc').update({
                [time_obj.day]:firestore.FieldValue.arrayUnion(time_obj.time_id)
            })
        })
        .then(()=>{
            firestore.collection('church_data').doc(time_obj.church_id).update({
                'times':firestore.FieldValue.arrayUnion(time_obj.time_id)
            })
        })
          .then(() => {
            dispatch({ type: 'ADD_TIME_SUCCESS' });
          }).catch(err => {
          dispatch({ type: 'ADD_TIME_ERROR' }, err);
        });
    }
  };
  
  export default addTime
  