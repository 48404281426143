import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className="d-flex flex-column"
      id="footer_container"
    >
      <Typography className="footer_text" variant="caption">
        <FavoriteIcon id="heart"/>Created with <span id="primary"> love </span> by Catholics just like you.
      </Typography>
      <Typography className="footer_text footer_bottom" variant="caption">
        <EmailIcon id="heart"/>Say hi to us at <a href="mailto:withyourspiritorg@gmail.com" id="email">withyourspiritorg@gmail.com</a>
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
