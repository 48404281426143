import { getChurchTimeByDays } from '../utils/api';
import { setChurchTimesBySearch } from './churchtimes';

export const SET_SEARCH = "SET_SEARCH";

// export function handleSetChurchTimesViaSearch(search) {
//   return (dispatch, getState) => {
//     const { datetime } = getState();
//     let days = datetime.days;
//     let church_times = [];
//     if(days.length === 0) {
//       church_times = getChurchTimeByDays(["Monday", "Tuesday", "Wednesday", "Thursday", 'Friday', "Saturday", "Sunday"])
//     }
//     dispatch(setSearch(search));
//     dispatch(setChurchTimesBySearch(search, church_times));
//   }
// }

export function setSearch(search) {
  return {
    type: SET_SEARCH,
    search
  }
}
