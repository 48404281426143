const addTimeDataReducer = (state = {}, action) => {
    switch (action.type) {
      case 'ADD_NEW_TIME_DATA_SUCCESS':
        console.log('add new time data success');
        return state;
      case 'ADD_NEW_TIME_DATA_ERROR':
        console.log('add new time data error');
        return state;
      default:
        return state;
    }
  };
  
  export default addTimeDataReducer;