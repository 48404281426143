

export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.login({
            email: credentials.email,
            password: credentials.password
        }).then(()=>{
            dispatch({ type: "LOGIN_SUCCESS"});
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err});
        });
    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        console.log('signing out...')

        firebase.auth().signOut().then(() => {
            console.log('signed out')
            dispatch({ type: 'SIGNOUT_SUCCESS'});
        }).catch(e=>{
            console.log('Error', e)
        });
    }
}

export const signUp = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
                newUser.email,
                newUser.password
        ).then((resp) => {
            return firestore.collection('users').doc(resp.user.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                phone: newUser.phone,
                city: newUser.city,
                state: newUser.state,
                zipcode: newUser.zipcode,
                time_zone: newUser.time_zone,
                church_admin_id: newUser.church_admin_id,
                subscribed_to_alerts: newUser.subscribed_to_alerts,
                subscribed_churches: [],
                livestreams_clicked: [],
                prayers_attended: [],
                favorite_times: []
            })
        }).then(() => {
            dispatch({type: 'SIGNUP_SUCCESS'})
        }).catch(err=>{
            dispatch({type: 'SIGNUP_ERROR', err})
        })
    }
}

export const updateUserEmail = (newEmail) =>{
    return (dispatch, getState, {getFirebase}) => {
        getFirebase().auth().currentUser.updateEmail(newEmail)
        .then(() => {
            dispatch({type: 'UPDATE_EMAIL_SUCCESS'})
        }).catch(err=>{
            dispatch({type: 'UPDATE_EMAIL_ERROR', err})
            console.log(err)
        })
    }
}

export const updateUserPassword = (newPassword) =>{
    return (dispatch, getState, {getFirebase}) => {
        getFirebase().auth().currentUser.updatePassword(newPassword)
        .then(() => {
            dispatch({type: 'UPDATE_PASSWORD_SUCCESS'})
        }).catch(err=>{
            dispatch({type: 'UPDATE_PASSWORD_ERROR', err})
        })
    }
}

export const editUserAttr = (uid, attribute, new_val) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      //   const profile = getState().firebase.profile;
      //   const authorId = getState().firebase.auth.uid;
  
      firestore.collection('users').doc(uid).update(
          {[attribute]: new_val}
      )
          .then(() => {
            dispatch({ type: 'EDIT_USER_ATTR_SUCCESS' });
          }).catch(err => {
          dispatch({ type: 'EDIT_USER_ATTR_ERROR' }, err);
        }); 
    }
  };

export const reauthenticateUser = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {
        var credential = getFirebase().auth.EmailAuthProvider.credential(
            credentials.email,
            credentials.password
          );
        return getFirebase().auth().currentUser.reauthenticateWithCredential(credential)
            .then(() => {
                dispatch({type: 'REAUTH_USER_SUCCESS'})
                return true
            }).catch(err=>{
                dispatch({type: 'REAUTH_USER_ERROR', err})
                console.log(err)
                return false
            })
    }
};