export const editUserAttrReducer = (state = {}, action) => {
    switch (action.type) {
      case 'EDIT_USER_ATTR_SUCCESS':
        console.log('edit user attribute success');
        return state;
      case 'EDIT_USER_ATTR_ERROR':
        console.log('edit user attribute error');
        return state;
      default:
        return state;
    }
  };

export const updateUserEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_EMAIL_SUCCESS':
      console.log('edit user email success');
      return state;
    case 'UPDATE_EMAIL_ERROR':
      console.log('edit user email error');
      return state;
    default:
      return state;
  }
};

export const updateUserPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_PASSWORD_SUCCESS':
      console.log('edit user password success');
      return state;
    case 'UPDATE_PASSWORD_ERROR':
      console.log('edit user password error');
      return state;
    default:
      return state;
  }
}

export const reauthenticateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case 'REAUTH_USER_SUCCESS':
      console.log('reauth user success');
      return state;
    case 'REAUTH_USER_ERROR':
      console.log('reauth user error');
      return state;
    default:
      return state;
  }
}