export const deleteTime = (time_obj) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      //   const profile = getState().firebase.profile;
      //   const authorId = getState().firebase.auth.uid;
  
      firestore.collection('time_data').doc(time_obj.time_id).delete()
        .then(()=>{
            firestore.collection('day_time_data').doc('dtd_doc').update({
                [time_obj.day]:firestore.FieldValue.arrayRemove(time_obj.time_id)
            })
        })
        .then(()=>{
            firestore.collection('church_data').doc(time_obj.church_id).update({
                'times':firestore.FieldValue.arrayRemove(time_obj.time_id)
            })
        })
          .then(() => {
            dispatch({ type: 'DELETE_TIME_SUCCESS' });
          }).catch(err => {
          dispatch({ type: 'DELETE_TIME_ERROR' }, err);
        });
    }
  };
  
  export default deleteTime
  