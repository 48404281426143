import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import church_icon from '../../icons/church_icon.png'
import {TextField} from '@material-ui/core'
import {states} from '../../utils/states'
import {diocese_options} from '../../utils/diocese_options'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import {Button} from '@material-ui/core';
import {editChurchDataAttr} from '../../actions/editChurchDataAttr'
import {time_zone_options} from '../../utils/time_zone_options'


class ChurchProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      church_name: this.props.church.church_name,
      diocese: this.props.church.diocese,
      city: this.props.church.city,
      state: this.props.church.state,
      time_zone: this.props.church.time_zone,
      facebook: this.props.church.facebook,
      twitter: this.props.church.twitter,
      instagram: this.props.church.instagram,
      youtube: this.props.church.youtube
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  toggleEdit = () => {
    this.setState({ editing : !this.state.editing} );
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCancel = () => {
    let resetState = {}
    for (let e in this.state){
      if (e !== 'editing'){
        resetState[e] = this.props.church[e]
      }
    }
    this.setState({editing:false, ...resetState})
  }

  handleSubmit = () => {
    try {
      for (let e in this.state){
        if (e != 'editing' && this.state[e] !== this.props.church[e]){
          this.props.editChurchDataAttr(this.props.church.church_id, e, this.state[e])
          this.props.church[e] = this.state[e]
        }
      }
      this.toggleEdit()
    } catch (error) {
      alert('There was an error in changing your details. Please try again later')
      console.log(error)
    }
  }
    

  render(){
    let canEdit = this.props.canEdit
   

    return(
      <div>
      {this.state.editing ? 
      <div id="church_profile" className="fade-in d-flex flex-column justify-content-center align-items-center">
      <img src={church_icon} id="church_profile_icon" alt='Church Profile Icon'/>
      <h5>Edit Profile</h5>
      <Button
            variant="contained"
            onClick={this.handleCancel}
          >
            cancel
          </Button>
      <Button
            color="secondary"
            variant="contained"
            onClick={this.handleSubmit}
          >
            save
          </Button>
      <TextField 
        label='Name'
        margin="dense"
        name="church_name"
        required
        value={this.state.church_name}
        onChange={this.handleChange}
        ></TextField>
        
      <TextField
        label="Diocese"
        margin="dense"
        name="diocese"
        select
        required
        SelectProps={{ native: true }}
        value={this.state.diocese}
        onChange={this.handleChange}
      >
        {diocese_options.map(option => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </TextField>
      <TextField 
        label='City' 
        margin="dense"
        name="city"
        required
        value={this.state.city}
        onChange={this.handleChange}
        >
      </TextField>
      <TextField
        label="State"
        margin="dense"
        name="state"
        select
        required
        SelectProps={{ native: true }}
        value={this.state.state}
        onChange={this.handleChange}
      >
        {states.map(option => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </TextField>
      <TextField
                label="Time Zone"
                margin="dense"
                name="time_zone"
                onChange={this.handleChange}
                select
                SelectProps={{ native: true }}
                value={this.state.time_zone}
              >
                {time_zone_options.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
      <TextField 
        label='Facebook' 
        margin="dense"
        name="facebook"
        type='url'
        value={this.state.facebook}
        onChange={this.handleChange}
        ></TextField>
      <TextField 
        label='Twitter' 
        margin="dense"
        name="twitter"
        type='url'
        value={this.state.twitter}
        onChange={this.handleChange}
        ></TextField>
        <TextField 
        label='Instagram' 
        margin="dense"
        name="instagram"
        type='url'
        value={this.state.instagram}
        onChange={this.handleChange}
        ></TextField>
        <TextField 
        label='Youtube' 
        margin="dense"
        name="youtube"
        type='url'
        value={this.state.youtube}
        onChange={this.handleChange}
        ></TextField>
    </div>
      :
      <div id="church_profile" className="fade-in d-flex flex-column justify-content-center align-items-center">
          <img src={church_icon} id="church_profile_icon" alt='Church Profile Icon'/>
          <h3 id="church_profile_name">{this.state.church_name}</h3>
          {canEdit ? 
            <IconButton color="primary" onClick={this.toggleEdit} aria-label="Edit Church Profile" component="span">
              <EditIcon />
            </IconButton>
            :
            null
          }
          <div className="church_info">{this.state.diocese}</div>
          <div className="church_info">{this.state.city}, {this.state.state}</div>
          <ul id="church_social_list" className="church_info">
            { this.state.facebook && <li><a target="_blank" href={this.state.facebook}>Facebook</a></li> }
            { this.state.twitter && <li><a target="_blank" href={this.state.twitter}>Twitter</a></li> }
            { this.state.instagram && <li><a target="_blank" href={this.state.instagram}>Instagram</a></li> }
            { this.state.youtube && <li><a target="_blank" href={this.state.youtube}>YouTube</a></li> }
          </ul>
        </div>
      }
      </div>
      )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editChurchDataAttr: (church_id, attr, newVal) => dispatch(editChurchDataAttr(church_id, attr, newVal)),
  }
}
export default connect(null, mapDispatchToProps)(ChurchProfile);
