import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { animateScroll as scroll } from 'react-scroll'

import { setDay } from '../../actions/datetime';
import { handleGetChurchTimesByDay, handleSetChurchTimesByDayOld } from '../../actions/churchtimes';
import { handlePagination } from '../../actions/pagination';
import up_arrow from '../../icons/up_arrow.png';
import Select from 'react-select';
import { states } from '../../utils/states'

import {
  LatestOrders
} from '../Dashboard/components/LatestOrders';
import DayPickerMini from '../Dashboard/components/DayPickerMini/DayPickerMini';
import AdminTimeList from "./AdminTimeList";


class Dashboard extends Component {
  componentDidMount() {
    const { dispatch, appLoaded } = this.props;
    if(appLoaded) {
      dispatch(setDay("Sunday"));
      dispatch(handleGetChurchTimesByDay("Sunday", ""));
    }
  }

  state = {
    inputValue: "",
    selectedOption: null,
  };

  handleChange = (event) => {
    const { dispatch, day } = this.props;
    dispatch(handleGetChurchTimesByDay(day, event.target.value));
    // dispatch(handleSetChurchTimesByDayOld(day, event.target.value));
  };

  handleSearchChange = (selectedOption) => {
    const { dispatch, day } = this.props;
    this.setState(
      { selectedOption },
      () => {
        dispatch(handleGetChurchTimesByDay(day, selectedOption.value));
        // dispatch(handleSetChurchTimesByDayOld(day, selectedOption.value));
      }
    );
  };

  render() {
    const { day, dispatch, pagination, times_count } = this.props;
    const { selectedOption } = this.state;

    const disabled = day.length === 0;
    const disabled_or_not = disabled ? "disabled_field" : "";

    return (
      <div>
        <div className="d-flex justify-content-center align-items-center scroll_to_top" onClick={() => {
          scroll.scrollToTop({
            duration: 350,
            delay: 0,
            smooth: true,
          });
        }}>
          <img src={up_arrow} id="up_icon" alt='Up Icon'/>
        </div>
        <Grid
          container
          spacing={4}
          direction="column"
        >
          <br />
          {/*<Grid*/}
            {/*item*/}
            {/*id="header_grid"*/}
            {/*lg={12}*/}
            {/*md={12}*/}
            {/*xl={12}*/}
            {/*xs={12}*/}
          {/*>*/}
            {/*<h3 className="header_dashboard">*/}
              {/*Search for Mass Live Streams*/}
            {/*</h3>*/}
            {/*<DayPickerMini/>*/}
            {/*<div className={`d-flex flex-column justify-content-center align-items-center" id="search_state_container ${disabled_or_not}`}>*/}
              {/*<div id="search_prompt_2">*/}
                {/*<span id="primary_span">2. Search by state</span>*/}
              {/*</div>*/}
              {/*<Select*/}
                {/*id="search_by_state"*/}
                {/*value={selectedOption}*/}
                {/*onChange={this.handleSearchChange}*/}
                {/*options={states}*/}
              {/*/>*/}
            {/*</div>*/}
          {/*</Grid>*/}

          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            {/*<Pagination className="d-flex pagination_upper justify-content-center" onChange={(event, page) => {*/}
              {/*dispatch(handlePagination(page))*/}
            {/*}} count={times_count}*/}
                        {/*defaultPage={1}*/}
                        {/*page={pagination+1}*/}
                        {/*color={'primary'}*/}
                        {/*variant="outlined" shape="rounded" />*/}
            <h3>Admin Time Approval</h3>
            <AdminTimeList />
            {/*<LatestOrders daySelected={selectedOption}/>*/}
          </Grid>
        </Grid>
        {/*<Pagination className="d-flex justify-content-center" onChange={(event, page) => {*/}
          {/*scroll.scrollToTop({*/}
            {/*duration: 400,*/}
            {/*delay: 0,*/}
            {/*smooth: true,*/}
          {/*});*/}
          {/*dispatch(handlePagination(page))*/}
        {/*}} count={times_count}*/}
                    {/*defaultPage={1}*/}
                    {/*page={pagination+1}*/}
                    {/*color={'primary'}*/}
                    {/*variant="outlined" shape="rounded" />*/}
      </div>
    );
  }
};

function mapStateToProps({ datetime, search, churchtimes, pagination, appLoaded }) {
  const empty = [];

  return {
    day: datetime.day ? datetime.day : empty,
    search: search ? search : "",
    times_count: churchtimes ? churchtimes.length : 0,
    pagination,
    appLoaded
  }
}

export default connect(mapStateToProps)(Dashboard);
