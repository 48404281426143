import React, { useState, useEffect, Fragment } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import {
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import { DatePicker } from './components'

import dove from '../../icons/dove_blue.png';
import { Footer } from '../../layouts/Main/components';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth_4.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const Landing = props => {

  const { history } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    // if (window.innerWidth < 505) props.dispatch(handleSetChurchTimesByDay(["Sunday"], ""));

    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);


  return (
    <Fragment>
      {window.innerWidth < 505 ?
        <div id="mobile" className="d-flex mobile flex-column justify-content-center align-items-center mt-5">
          <h4 id="mobile_header">With Your Spirit</h4>
          <div id="view_on_comp">A database of Catholic Mass Live Streams.</div>
          <Link to="/search">
            <Button id="find_masses_mobile" variant="contained" color="primary">
              Find Masses Now
            </Button>
          </Link>
          <div id="view_on_comp_2">
            <Link to="/addchurch">Add your church to our database!</Link>
          </div>
          <img src={dove} className="fade-in" id="dove_icon_mobile" alt='Choose Location Icon'/>
          <Footer />
        </div>
        :
        <div className={classes.root}>
          <Grid
            className={classes.grid}
            container
          >
            <Grid
              className={classes.quoteContainer}
              item
              lg={5}
              id="home_pic_grid"
            >
              <div className={classes.quote}>
                <div className={classes.quoteInner}>
                  <Typography
                    className={classes.quoteText}
                    variant="h1"
                    id="home_text_h1"
                  >
                    <span className="home_text">Find Church.</span>
                    <br />
                    <span className="home_text">Any Time.</span>
                    <br />
                    <span className="home_text">From Home.</span>
                  </Typography>
                </div>
              </div>
            </Grid>
            {/*{*/}
              {/*window.innerHeight < 760 ?*/}
                <div id="mobile_2" className="d-flex mobile flex-column justify-content-center align-items-center">
                  <h4 id="mobile_header">With Your Spirit</h4>
                  <div id="view_on_comp">A database of Catholic Mass Live Streams.</div>
                  <Link to="/search">
                    <Button id="find_masses_mobile" variant="contained" color="primary">
                      Find Masses Now
                    </Button>
                  </Link>
                  <div id="view_on_comp_2">
                    <Link to="/addchurch">Add your church to our database!</Link>
                  </div>
                  <img src={dove} className="fade-in" id="dove_icon_mobile" alt='Choose Location Icon'/>
                  <Footer />
                </div>
                {/*:*/}
            {/*<Grid*/}
              {/*className={classes.content}*/}
              {/*item*/}
              {/*lg={7}*/}
              {/*// xs={12}*/}
              {/*id="daypickergrid"*/}
            {/*>*/}
              {/*<div id="home_content_container" className={`d-flex flex-column justify-content-center fade-in ${classes.content}`}>*/}
                {/*<div className={`d-flex flex-column justify-content-center ${classes.contentBody}`}>*/}
                  {/*<h3>*/}
                    {/*Search for Catholic Mass Live Streams*/}
                  {/*</h3>*/}
                  {/*<div className="d-flexjustify-content-center mb-4 align-items-center">*/}
                    {/*<span className="wys_text">With Your Spirit</span> is a database of Catholic Mass Live Streams.*/}
                  {/*</div>*/}
                  {/*<DatePicker />*/}
                  {/*<Link to="/search">*/}
                    {/*<Button id="find_masses" variant="contained" color="primary">*/}
                      {/*Find Masses Now*/}
                    {/*</Button>*/}
                  {/*</Link>*/}
                  {/*<div id="view_on_comp_3">*/}
                    {/*<a target="_blank" href="https://docs.google.com/forms/d/1D8JwXY15Tz5GdZcdaJlMpKuvPPpHXx0KtJygSsyqaoo">Add your church to our database!</a>*/}
                  {/*</div>*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</Grid>*/}
            {/*}*/}
          </Grid>
        </div>
      }
    </Fragment>
  );
};

Landing.propTypes = {
  history: PropTypes.object
};

export default connect()(withRouter(Landing));
