import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Redirect} from 'react-router-dom'

import ChurchProfile from './ChurchProfile'
import ChurchStreamList from './ChurchStreamList';


class Church extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    if (!this.props.church) return <Redirect to='/not-found' />


    let canEdit = false
    if (!this.props.profile.isEmpty && 
        (this.props.profile.church_admin_id===this.props.church.church_id || 
        this.props.profile.token.claims.admin === true)){
      canEdit = true
    }

    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <ChurchProfile church={this.props.church} canEdit={canEdit}/>
        <ChurchStreamList church={this.props.church} canEdit={canEdit}/>
      </div>
    )
  }
}

function mapStateToProps({ allChurches, firebase }, props) {

  return {
    church: allChurches[props.match.params.id],
    profile: firebase.profile
  }

}

export default connect(mapStateToProps)(Church);
