// used only in NewChurchForm

export const addTimeData = (time_datas) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
    //   const profile = getState().firebase.profile;
    //   const authorId = getState().firebase.auth.uid;

    time_datas.forEach(t=>{
        firestore.collection('time_data').doc(t.time_id).set({
            ...t
          }).then(() => {
            dispatch({ type: 'ADD_NEW_TIME_DATA_SUCCESS' });
          }).catch(err => {
            dispatch({ type: 'ADD_NEW_TIME_DATA_ERROR' }, err);
          });
        })
    }
};

export default addTimeData