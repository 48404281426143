import { getChurchDataAsDict } from '../utils/fb_api';
import {setAppLoaded} from "./appLoaded";
import { handleSetAllTimes } from "./allTimes";
export const SET_ALL_CHURCHES = "SET_ALL_CHURCHES";

export function handleInitializeData() {
  return (dispatch, getState) => {
    getChurchDataAsDict()
      .then(churches  => dispatch(setAllChurches(churches)))
      .then(() => dispatch(handleSetAllTimes()))
    // getAllChurches()
    //   .then(churches => dispatch(setAllChurches(churches)))
    //   .then(() => dispatch(handleSetAllTimes()))
  }
}

function setAllChurches(churches) {
  return {
    type: SET_ALL_CHURCHES,
    churches
  }
}


