export const addDayTimeData = (day_time_data) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    //   const profile = getState().firebase.profile;
    //   const authorId = getState().firebase.auth.uid;

    for (let key in day_time_data){
      let ref = firestore.collection('day_time_data').doc('dtd_doc')
      ref.update({
        [key]: firestore.FieldValue.arrayUnion(...day_time_data[key])
      })
        .then(() => {
          dispatch({ type: 'EDIT_DAY_TIME_DATA_SUCCESS' });
        }).catch(err => {
        dispatch({ type: 'EDIT_DAY_TIME_DATA_ERROR' }, err);
      });
    }
  }
};

export default addDayTimeData
