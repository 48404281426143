import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {AppBar, Toolbar, Badge, Hidden, IconButton, TextField} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import dove from "../../../../icons/dove.png"
import pray from '../../../../icons/pray_icon_white.png';
import {signOut} from '../../../../actions/user&auth'
import {connect} from 'react-redux'


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { auth, className, onSidebarOpen, ...rest } = props;
  
  const classes = useStyles();

  const [notifications] = useState([]);

  const userLinks = auth.uid 
    ? <div>
        <button id="add_here" onClick={props.signOut}>Sign Out</button>
        <Link to={"/user/"+auth.uid}>
          <img src={pray} id="dove_icon" alt='Choose Location Icon'/>
        </Link>
      </div>
    : <span></span>
    {/*<span id="add_here"><Link to="/signin">Sign In</Link></span>*/}
  const addChurchOrReturn = window.location.pathname === '/search' 
    ? <span id="add_here"><Link to="/addchurch">Add your church here</Link></span>
    : <span id="add_here"><Link to="/search">Return to search page</Link></span>

  // const userLinks = auth.uid ? <SignedInLinks/> : <SignedOutLinks/>


  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <Link to="/">
          <div className="d-flex justify-content-center align-items-center">
            <img src={dove} id="dove_icon" alt='Choose Location Icon'/>
            <span id="nav_title">With Your Spirit</span>
          </div>
        </Link>
        <div className={classes.flexGrow} />
        {addChurchOrReturn}
        {/* <span id="add_here"><Link to="/addchurch">Add your church here</Link></span>
        <span id="add_here"><Link to="/search">Return to search page</Link></span> */}
        {userLinks}



        {/* const links = auth.uid ? <SignedInLinks profile={profile}/> : <SignedOutLinks/>;
        return (
        <nav className="nav-wrapper grey darken-3">
            <div className="container">
                <Link to="/" className="brand-logo">MarioPlan</Link>
                {links}
            </div>
        </nav> */}

        {/*<Hidden mdDown>*/}
          {/*<IconButton color="inherit">*/}
            {/*<Badge*/}
              {/*badgeContent={notifications.length}*/}
              {/*color="primary"*/}
              {/*variant="dot"*/}
            {/*>*/}
              {/*<NotificationsIcon />*/}
            {/*</Badge>*/}
          {/*</IconButton>*/}
          {/*<IconButton*/}
            {/*className={classes.signOutButton}*/}
            {/*color="inherit"*/}
          {/*>*/}
            {/*<InputIcon />*/}
          {/*</IconButton>*/}
        {/*</Hidden>*/}
        {/*<Hidden lgUp>*/}
          {/*<IconButton*/}
            {/*color="inherit"*/}
            {/*onClick={onSidebarOpen}*/}
          {/*>*/}
            {/*<MenuIcon />*/}
          {/*</IconButton>*/}
        {/*</Hidden>*/}
      </Toolbar>
    </AppBar>
  );
};

// Topbar.propTypes = {
  
// };

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
