import { SET_ALL_CHURCHES } from "../actions/allChurches";

export default function allChurches(state = {}, action) {

  switch(action.type) {
    case SET_ALL_CHURCHES:
      return action.churches;
    default:
      return state;
  }
}
