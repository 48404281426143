import { handleGetChurchTimesByDay, handleSetChurchTimesByDayOld } from './churchtimes';
import { changePaginationIndex } from './pagination';

export const SET_DATE_TIME = "SET_DATE_TIME";
export const SET_DAY = "SET_DAY";
export const SET_TIME = "SET_TIME";
export const TOGGLE_DAY = "TOGGLE_DAY";


export function setDateTime(day, time){
  return {
    type: SET_DATE_TIME,
    day,
    time
  }
}

export function setDay(day) {
  return {
    type: SET_DAY,
    day,
  }
}

export function handleToggleDay(day) {
  return (dispatch, getState) => {
    const { datetime, search } = getState();
    // let newDays = datetime.days.includes(day) ? datetime.days.concat([day]) : datetime.days.filter(d => d !== day);
    dispatch(setDay(day));
    dispatch(handleGetChurchTimesByDay(day, search));
    // dispatch(handleSetChurchTimesByDayOld(day, search));
  }
}

export function toggleDay(day) {
  return {
    type: TOGGLE_DAY,
    day
  }
}

export function setTime(time) {
  return {
    type: SET_TIME,
    time,
  }
}
