import { SET_DISPLAYED_TIMES } from '../actions/displayedTimes';

export default function displayedTimes(state = [], action) {
  switch(action.type) {
    case SET_DISPLAYED_TIMES:
      return action.times;
    default:
      return state;
  }
}
