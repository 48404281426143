import 'date-fns';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/styles';
import AccessTime from '@material-ui/icons/AccessTime'
import { connect } from 'react-redux';

import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimeKeeper from 'react-timekeeper';

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import Daypicker from '../DayPicker'
import Zipcode from '../ZipCode/Zipcode';
import DayPicker from '../DayPicker/DayPicker';
import { setTime } from '../../../../actions/datetime';


// export default function DatePickerMini() {
class DatePicker extends Component {

  state = {
    time: '12:00pm',
  };

  setTime = (time) => {
    this.setState(
      {
        time: time
      },
      () => {
        this.props.dispatch(setTime(time));
      }
    )
  };

  render() {
    const { className, ...rest } = this.props;
    const { showTime, time } = this.state;


    return (

          <MuiPickersUtilsProvider id="date_form" utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <DayPicker />
                {/*<TimeKeeper*/}
                  {/*time={this.state.time}*/}
                  {/*onChange={(newTime) => this.setTime(newTime.formatted)}*/}
                  {/*switchToMinuteOnHourSelect*/}
                  {/*coarseMinutes={15}*/}
                  {/*forceCoarseMinutes*/}
                {/*/>*/}
              {/*}*/}
              {/*{!showTime &&*/}
                {/*<div id="time_container" onClick={() => this.setShowTime(true)}>*/}
                  {/*<div id="time_box">{time}</div>*/}
                {/*</div>*/}
              {/*}*/}
            </Grid>
          </MuiPickersUtilsProvider>
      //   </CardContent>
      // </Card>
    );
  }
}

DatePicker.propTypes = {
  className: PropTypes.string
};

export default connect()(DatePicker);
