export const addNewChurch = (church_data) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
    //   const profile = getState().firebase.profile;
    //   const authorId = getState().firebase.auth.uid;
      firestore.collection('church_data').doc(church_data.church_id).set({
        ...church_data
      }).then(() => {
        dispatch({ type: 'ADD_NEW_CHURCH_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'ADD_NEW_CHURCH_ERROR' }, err);
      });
    }
  };