import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
import {signUp} from '../actions/user&auth'
import {states} from '../utils/states'
import {time_zone_options} from '../utils/time_zone_options'
import Select from 'react-select'
import { Card } from '@material-ui/core';

// email, pass, phone only required
class SignUp extends Component {
    state = {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phone: '',
        city: '',
        state: '',
        zipcode: '',
        time_zone: '',
        subscribed_churches: [],
        subscribed_to_alerts: false,
        livestreams_clicked: [],
        prayers_attended: [],
        church_admin_id: null,
        favorite_times: []
    }
 
    handleChange = e =>{
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSumbit = e =>{
        e.preventDefault()
        this.props.signUp(this.state)
    }

    handleSelectChange = (n, v) => {
        this.setState({
            [n]: v['value']
        })
    }
    
    render() {
        const {auth, authError} = this.props;
        if (auth.uid) return <Redirect to='/search' />
        return (
            <Card className="form_card">
                <form onSubmit={this.handleSumbit} className="white">
                    <h5 className="grey-text text-darken-3">Sign Up</h5>
                    <div className="input-field">
                        <label htmlFor="firstName">First Name</label>
                        <input required type="text"   id="firstName" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="lastName">Last Name</label>
                        <input required type="text"   id="lastName" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="email">Email</label>
                        <input required type="email"   id="email" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="password">Password</label>
                        <input required type="password"   id="password" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="phone">Phone Number (optional)</label>
                        <input type="text" id="phone" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="city">City (optional)</label>
                        <input type="text"   id="city" onChange={this.handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="state">State (optional</label>
                        <Select options={states} onChange={this.handleSelectChange.bind(this, 'state')} />        
                    </div>
                    <div className="input-field">
                        <label htmlFor="zipcode">ZIP Code (optional)</label>
                        <input type="text" id="zipcode" onChange={this.handleChange}/>
                    </div>
                    <div>
                    <label>Time Zone (optional)</label>
                        <Select options={time_zone_options} onChange={this.handleSelectChange.bind(this, 'time_zone')} />
                    </div>
                    
                    <div className="input-field">
                        <button className="form_button btn btn-success btn-block mb-2">Sign Up</button>
                        <div className="red-text center">
                            { authError ? <p> { authError } </p> : null } 
                        </div>
                    </div>
                </form>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
