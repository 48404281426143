import { combineReducers } from "redux";

import datetime from './datetime'
import churchtimes from './churchtimes'
import search from './search'
import pagination from './pagination'
import displayedTimes from './displayedTimes'
import addNewChurchReducer from './addNewChurchReducer'
import addTimeDataReducer from './addTimeDataReducer'
import addDayTimeDataReducer from './addDayTimeDataReducer'
import editTimeDataAttrReducer from './editTimeDataAttrReducer'
import editChurchDataAttrReducer from './editChurchDataAttrReducer'
import {editUserAttrReducer,updateUserEmailReducer,updateUserPasswordReducer} from './user&AuthReducers'
import allTimes from './allTimes'
import allChurches from './allChurches'
import appLoaded from './appLoaded'
import dayTimes from './dayTimes'
import authReducer from './authReducer'

import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

export default combineReducers({

  appLoaded,
  allChurches,
  allTimes,
  dayTimes,
  datetime,
  search,
  pagination,
  churchtimes,
  displayedTimes,
  addNewChurchReducer,
  addTimeDataReducer,
  addDayTimeDataReducer,
  editTimeDataAttrReducer,
  editChurchDataAttrReducer,
  editUserAttrReducer,
  updateUserEmailReducer,
  updateUserPasswordReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer
})
