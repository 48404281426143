// import { getChurchTimeByDays, getAllTimesByDayOld } from '../utils/api';
import { setSearch } from './search';
import { handleSetDisplayedTimes } from './displayedTimes';
import { changePaginationIndex } from "./pagination";
import { getAllTimesAndChurchesByDay } from "../utils/fb_api";

export const SET_CHURCH_TIMES_BY_SEARCH = "SET_CHURCH_TIMES_BY_SEARCH";
export const SET_CHURCH_TIMES_BY_DAY = "SET_CHURCH_TIMES_BY_DAY";
export const SORT_BY_TIMEZONE = "SORT_BY_TIMEZONE";
export const SORT_BY_CHURCH_NAME = "SORT_BY_CHURCH_NAME";
export const SORT_BY_CITY = "SORT_BY_CITY";
export const SORT_BY_STATE = "SORT_BY_STATE";
export const SORT_BY_TIME = "SORT_BY_TIME";
export const GET_FROM_ALL_TIMES_BY_DAY = "GET_FROM_ALL_TIMES_BY_DAY";

// SET_CHURCH_TIMES
export const SET_CHURCH_TIMES = "SET_CHURCH_TIMES_BY_DAY";


export function handleGetChurchTimesByDay(day, search) { // new
  return (dispatch, getState) => {
    const { pagination, dayTimes, allTimes, allChurches } = getState();
    let dayTimeIDs = dayTimes[day.toLowerCase()];
    let timesObjs = dayTimeIDs.map((_id) => {
      return allTimes[_id];
    });

    dispatch(setSearch(search));
    dispatch(changePaginationIndex(1));

    dispatch(setChurchTimesByDay(timesObjs, search, pagination, allChurches));
    dispatch(handleSetDisplayedTimes())
  }
}

// export function getFromAllTimesByDay(day) {
//   return {
//     type: GET_FROM_ALL_TIMES_BY_DAY,
//     day
//   }
// }
//
// export function handleSetChurchTimesByDayOld(day, search) { // old
//   return (dispatch, getState) => {
//     const { pagination } = getState();
//     dispatch(setSearch(search));
//     dispatch(changePaginationIndex(1))
//     getChurchTimeByDays(day)
//       .then(church_times => dispatch(setChurchTimesByDay(church_times, search, pagination)))
//       .then(church_times => dispatch(handleSetDisplayedTimes()))
//   }
// }

export function setChurchTimesBySearch(search, church_times) {
  return {
    type: "SET_CHURCH_TIMES_BY_SEARCH",
    search,
    church_times
  }
}


export function setChurchTimesByDay(church_times, search, pagination, allChurches) {
  return {
    type: "SET_CHURCH_TIMES_BY_DAY",
    church_times,
    search,
    pagination,
    allChurches
  }
}

export function handleSortByChurchName(toggleAsc) {
  return (dispatch, getState) => {
    const { allChurches } = getState();
    dispatch(sortByChurchName(toggleAsc, allChurches));
    dispatch(changePaginationIndex(1))
    dispatch(handleSetDisplayedTimes());
  }
}

export function sortByChurchName(toggleAsc, allChurches) {
  return {
    type: "SORT_BY_CHURCH_NAME",
    toggleAsc,
    allChurches
  }
}

export function handleSortByCity(toggleAsc) {
  return (dispatch, getState) => {
    const { allChurches } = getState();
    dispatch(sortByCity(toggleAsc, allChurches));
    dispatch(changePaginationIndex(1))
    dispatch(handleSetDisplayedTimes());
  }
}

function sortByCity(toggleAsc, allChurches) {
  return {
    type: "SORT_BY_CITY",
    toggleAsc,
    allChurches
  }
}

export function handleSortByState(toggleAsc) {
  return (dispatch, getState) => {
    const { allChurches } = getState();
    dispatch(sortByState(toggleAsc, allChurches));
    dispatch(changePaginationIndex(1))
    dispatch(handleSetDisplayedTimes());
  }
}

function sortByState(toggleAsc, allChurches) {
  return {
    type: "SORT_BY_STATE",
    toggleAsc,
    allChurches
  }
}

export function handleSortByTime(toggleAsc) {
  return (dispatch, getState) => {
    const { pagination } = getState();
    dispatch(sortByTime(toggleAsc, pagination));
    dispatch(changePaginationIndex(1))
    dispatch(handleSetDisplayedTimes());
  }
}

function sortByTime(toggleAsc) {
  return {
    type: "SORT_BY_TIME",
    toggleAsc
  }
}


export function handleSortByTimezone(toggleAsc) {
  return (dispatch, getState) => {
    const { pagination } = getState();
    dispatch(sortByTimezone(toggleAsc, pagination));
    dispatch(changePaginationIndex(1))
    dispatch(handleSetDisplayedTimes());
  }
}

export function sortByTimezone(toggleAsc) {
  return {
    type: "SORT_BY_TIMEZONE",
    toggleAsc
  }
}
