import React, { Component, Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { connect } from "react-redux"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import editTimeDataAttr from '../../actions/editTimeDataAttr'
import sortBy from 'lodash/sortBy'
import firebase from '../../config/fbConfig'
import {Redirect} from 'react-router-dom'


import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { StatusBullet } from 'components';
import ChurchTableCells from "../Dashboard/components/LatestOrders/ChurchTableCells";
import { handleViewLivestreamClick } from '../../actions/clickEvents';


class AdminTimeList extends Component {

handleApprovalToggle = (time) => {
    this.props.editTimeDataAttr(time.time_id, "approved", !time.approved)
};


  render() {

    const { auth, profile, churchtimes, times_count, day, daySelected } = this.props;
    // const { timezoneToggleAsc, churchnameToggleAsc, cityToggleAsc, stateToggleAsc, timeToggleAsc } = this.state;
    // let day_selected = daySelected ? `in ${daySelected.label}` : "";
    // let table_prompt = `${day} Mass Live Streams ${day_selected}`;
    // let table_subheader = `${times_count} results found`;
    if (auth.isEmpty){
      return <Redirect to='/signin' />
    }
    
    // redirects user to dashboard if they don't have admin priviledges
    if (profile.token.claims.admin !== true){
      return <Redirect to='/search' />
    }

    return (
      
      <Fragment>
        <Card
          id="church_table"
        >
          <CardHeader
            title="Header"
            subheader="Subheader"
          />
          <Divider />
          <CardContent className="tablecontent">
            <PerfectScrollbar>
              <div className="tableinner">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell id="semiwide-column">
                        <div className="table_header_inner" onClick={this.handleTimeToggle}>
                          Approval
                        </div>
                      </TableCell>
                      <TableCell>Link</TableCell>
                      <TableCell>Day</TableCell>
                      <TableCell id="semiwide-column">
                        <div className="table_header_inner" onClick={this.handleTimeToggle}>
                          Time
                        </div>
                      </TableCell>
                      <TableCell className="timezone_column">
                        <div className="table_header_inner" onClick={this.handleTimezoneToggle}>
                          Timezone
                        </div>
                      </TableCell>
                      <TableCell id="church_name_column">
                        <div className="table_header_inner" onClick={this.handleChurchNameToggle}>
                          Church
                        </div>
                      </TableCell>
                      <TableCell id="semiwide_column">
                        <div className="table_header_inner" onClick={this.handleCityToggle}>
                          City
                        </div>
                      </TableCell>
                      <TableCell id="semiwide_column">
                        <div className="table_header_inner" onClick={this.handleStateToggle}>
                          State
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {
                      churchtimes
                        .map(time => (
                          <TableRow
                            hover
                            key={time.time_id}
                          >
                            <TableCell id="semiwide-column">
                              <div>
                                {time.approved
                                  ? <button className="table_header_inner unapprove_btn" onClick={()=>{this.handleApprovalToggle(time)}}>Approved</button>
                                  : <button className="table_header_inner unapprove_btn" style={{backgroundColor: '	#FF0038'}} onClick={()=>{this.handleApprovalToggle(time)}}>Not Approved</button>
                                }
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tablestatusContainer">
                                <a target="_blank" href={time.livestream_link}><div onClick={() => {
                                  // handleViewLivestreamClick();
                                }}>Live Stream</div></a>
                              </div>
                            </TableCell>
                            <TableCell>{time.day}</TableCell>
                            <TableCell>{time.time}</TableCell>
                            <TableCell>{time.time_zone}</TableCell>
                            {/* <TableCell>{time.time_id}</TableCell> */}
                            <ChurchTableCells church_id={time.church_id}/>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </Fragment>
    )
  };
};


function mapStateToProps({firebase, allTimes, churchtimes, displayedTimes, datetime }) {

  let count = churchtimes ? churchtimes.flat().length : 0;

  let times = []
  for (let t in allTimes){
    times.push(allTimes[t])
  }

  let sortedTimes = sortBy(times, (time)=>{
    return time.approved
  })
  // console.log(sortedTimes)

  return {
    churchtimes: sortedTimes,
    times_count: count,
    day: datetime.day ? datetime.day : "",
    auth: firebase.auth,
    profile: firebase.profile
  }
}


const mapDispatchToProps = dispatch => {
  return {
      editTimeDataAttr: (time_id, attribute, new_val) => dispatch(editTimeDataAttr(time_id, attribute, new_val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTimeList);

