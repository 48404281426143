import 'rc-time-picker/assets/index.css';

import React from 'react';
import ReactDom from 'react-dom';

import moment from 'moment';

import TimePicker from 'rc-time-picker';

const format = 'h:mm a';

const now = moment().hour(0).minute(0);

function onChange(value) {
  console.log(value && value.format(format));
}

const TimeSelector = () =>{
  ReactDom.render(
    <TimePicker
      showSecond={false}
      defaultValue={now}
      className="xxx"
      onChange={onChange}
      format={format}
      use12Hours
      inputReadOnly
    />,
    document.getElementById('__react-content')
  );
}

export default TimeSelector