import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { connect } from 'react-redux';

import { setDay } from '../../../../actions/datetime'
import { Card } from '@material-ui/core';

class DayPicker extends Component {
  componentDidMount() {
    this.props.dispatch(setDay(Object.keys(this.state).filter(d => this.state[d] === true )));
  }

  state = {
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: true,
  };

  handleChange = event => {
    this.setState(
      { ...this.state, [event.target.name]: event.target.checked },
      () => {
        this.props.dispatch(setDay(Object.keys(this.state).filter(d => this.state[d] === true )));
      });
  };

  render() {
    return (

      <FormGroup column="true">

        <Card className={this.state.Sunday ? "check_active" : ""}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.Sunday}
                onChange={this.handleChange}
                name="Sunday"
                color="primary"
              />
            }
            label="Sunday"
          />
        </Card>

        <Card className={this.state.Saturday ? "check_active" : ""}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.Saturday}
                onChange={this.handleChange}
                name="Saturday"
                color="primary"
              />
            }
            label="Saturday"
          />
        </Card>

        <Card className={this.state.Friday ? "check_active" : ""}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.Friday}
                onChange={this.handleChange}
                name="Friday"
                color="primary"
              />
            }
            label="Friday"
          />
        </Card>

        <Card className={this.state.Thursday ? "check_active" : ""}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.Thursday}
                onChange={this.handleChange}
                name="Thursday"
                color="primary"
              />
            }
            label="Thursday"
          />
        </Card>

        <Card className={this.state.Wednesday ? "check_active" : ""}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.Wednesday}
                onChange={this.handleChange}
                name="Wednesday"
                color="primary"
              />
            }
            label="Wednesday"
          />
        </Card>

        <Card className={this.state.Tuesday ? "check_active" : ""}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.Tuesday}
                onChange={this.handleChange}
                name="Tuesday"
                color="primary"
              />
            }
            label="Tuesday"
          />
        </Card>

        <Card className={this.state.Monday ? "check_active" : ""}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.Monday}
                onChange={this.handleChange}
                name="Monday"
                color="primary"
              />
            }
            label="Monday"
          />
        </Card>

    </FormGroup>
    )
  }
}

export default connect()(DayPicker);
