import { CHANGE_PAGINATION_INDEX } from '../actions/pagination';

export default function pagination(state = 0, action) {
  switch(action.type) {
    case CHANGE_PAGINATION_INDEX:
      return action.page-1;
    default:
      return state;
  }
}
