import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import './App.css';
import dove from 'icons/dove_blue.png';
import Loader from 'react-loader-spinner'
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { handleSetAllTimes } from "./actions/allTimes";
import { connect } from "react-redux"
import {setAppLoaded} from "./actions/appLoaded";
import { handleInitializeData } from "./actions/allChurches";

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

class App extends Component {
  componentDidMount() {
    this.props.dispatch(handleInitializeData());
    console.log("With Your Spirit");
    this.props.dispatch(setAppLoaded(false));
  }


  render() {
    return (
      <ThemeProvider theme={theme}>
        {!this.props.appLoaded
          ?
          <div id="spinner_container">
            <h5 id="mobile_header_loading">With Your Spirit</h5>
            <Loader type="Hearts" color="#4169e1" height={80} width={80} />
            <span id="loading_streams">Loading Mass Streams</span>
          </div>
          :
          <Router history={browserHistory}>
            <Routes/>
          </Router>
        }
      </ThemeProvider>
    );
  }
}

function mapStateToProps({ appLoaded }) {
  return {
    appLoaded
  }
}


export default connect(mapStateToProps)(App);
