const addNewChurchReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_NEW_CHURCH_SUCCESS':
      console.log('add new church success');
      return state;
    case 'ADD_NEW_CHURCH_ERROR':
      console.log('add new church error');
      return state;
    default:
      return state;
  }
};
  
export default addNewChurchReducer;