import { handleSetDisplayedTimes } from './displayedTimes';

export const CHANGE_PAGINATION_INDEX = "CHANGE_PAGINATION_INDEX";

export function handlePagination(page) {
  return (dispatch, getState) => {
    const { search, churchtimes } = getState();
    dispatch(changePaginationIndex(page));
    dispatch(handleSetDisplayedTimes())
  }
}

export function changePaginationIndex(page) {
  return {
    type: CHANGE_PAGINATION_INDEX,
    page
  }
}

