import React from 'react'
import { Card } from '@material-ui/core';
import Select from 'react-select'


export default function Step3(props) {
    if (props.currentStep !== 3) {
    return null
    }

  const { contact_name, contact_title, contact_number, contact_email, currentStep } = props.state;

  let readyForSubmit = (currentStep === 3 &&
    contact_name.length > 0 &&
    contact_title.length > 0 &&
    contact_number.length > 0 &&
    contact_email.length > 0)


    return(
      <React.Fragment>
        <div className="form-group form_inner">
          <Card className="form_card">
            <label htmlFor="contact_name">Primary Staff Contact Name</label>
            <input type="text" id='contact_name' onChange={props.handleChange} />
          </Card>
          <Card className="form_card">
            <label htmlFor="contact_title">Title of Staff Contact</label>
            <input type="text" id='contact_title' onChange={props.handleChange} />
          </Card>
          <Card className="form_card">
            <label htmlFor="contact_number">Phone Number</label>
            <input type="text" id='contact_number' onChange={props.handleChange} />
          </Card>

          <Card className="form_card">
            <label htmlFor="contact_email">Email</label>
            <input type="email" id='contact_email' onChange={props.handleChange} />
          </Card>

          <Card className="form_card">
            <label htmlFor="facebook">Facebook</label>
            <input type="text" id='facebook' onChange={props.handleChange}/>
          </Card>

          <Card className="form_card">
            <label htmlFor="youtube">Youtube</label>
            <input type="text" id='youtube' onChange={props.handleChange}/>
          </Card>

          <Card className="form_card">
            <label htmlFor="twitter">Twitter</label>
            <input type="text" id='twitter' onChange={props.handleChange}/>
          </Card>

          <Card className="form_card">
            <label htmlFor="instagram">Instagram</label>
            <input type="text" id='instagram' onChange={props.handleChange}/>
          </Card>

          <Card className="form_card">
            <label for="subscribed">Would you like to be added to an email distribution list with updates related to video streaming, virtual gatherings, and other online resources?</label>
            <Select id="subscribed" options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]} onChange={props.handleSelectChange.bind(this, 'subscribed')} placeholder="Subscribed to for Updates" />
          </Card>
        </div>
        { !readyForSubmit ? <span id="primary_span">* Please fill out all fields above to continue</span> : ""}
        <button disabled={!readyForSubmit} className="form_button btn btn-success btn-block mb-2">Submit</button>
        <div className="m-2">or</div>
    </React.Fragment>
  );
}