import React from 'react'
import TimePicker from 'rc-time-picker';
import Select from 'react-select'
import {language_options} from '../../utils/language_options'
import { Card } from '@material-ui/core';


export default function Step2(props) {
    if (props.currentStep !== 2) {
    return null
    } 
    if (props.days.length === 0){
      return(
        <h5>Please go back to the previous page and select mass livestream days to continue</h5>
      )
    }
    return(
      <div className="form-group form_inner">
      {props.days.map(day => {
            let dayLabel = day.label
            let dayVal = day.value
            props.dayToTimeData[dayVal] = {
              livestream_link: "",
              service1: {time: "", language: ""},
              service2: {time: "", language: ""},
              service3: {time: "", language: ""}
            }
            return (
              <Card className="form_card">
                <form>
                <h4>{dayLabel} Livestream Info</h4>

                <div className="d-flex flex-column mb-3">
                  <label htmlFor='stream_link'>Link to {dayLabel} Livestream:</label>
                  <input type="text" id='stream_link' onChange={props.handlePageTwoChange.bind(this, 'link', null, dayVal)} />
                </div>

                <hr/>

                <div className="d-flex flex-row mb-3">
                  <span className="service_time_text"><span className="mr-2 font-weight-bolder" id="primary_span">•</span>Starting time of 1st {dayLabel} mass</span>
                  <TimePicker
                      id="time1"
                      showSecond={false}
                      onChange={props.handlePageTwoChange.bind(this, 'time', 'service1', dayVal)}
                      format={'h:mm a'}
                      use12Hours
                      inputReadOnly

                  />
                </div>

                <div className="d-flex flex-column">
                  <label><span className="mr-2 font-weight-bolder" id="primary_span">•</span>Language of 1st {dayLabel} mass</label>
                  <Select placeholder="Select Language..." options={language_options} onChange={props.handlePageTwoChange.bind(this, 'lang', 'service1', dayVal)} /><br></br>
                </div>

                <hr/>

                <div className="d-flex flex-row mb-3">
                  <span className="service_time_text"><span className="mr-2 font-weight-bolder" id="primary_span">•</span>Starting time of 2nd {dayLabel} mass <span id="primary_span">(if applicable)</span></span>
                  <TimePicker
                      id="time2"
                      showSecond={false}
                      onChange={props.handlePageTwoChange.bind(this, 'time', 'service2', dayVal)}
                      format={'h:mm a'}
                      use12Hours
                      inputReadOnly
                  />
                </div>
                <div className="d-flex flex-column">
                  <label><span className="mr-2 font-weight-bolder" id="primary_span">•</span>Language of 2nd {dayLabel} mass</label>
                  <Select placeholder="Select Language..." options={language_options} onChange={props.handlePageTwoChange.bind(this, 'lang', 'service2', dayVal)} /><br></br>
                </div>

                <hr/>

                <div className="d-flex flex-row mb-3">
                  <span className="service_time_text"><span className="mr-2 font-weight-bolder" id="primary_span">•</span>Starting time of 3rd {dayLabel} mass <span id="primary_span">(if applicable)</span></span>
                  <TimePicker
                      id="time3"
                      showSecond={false}
                      onChange={props.handlePageTwoChange.bind(this, 'time', 'service3', dayVal)}
                      format={'h:mm a'}
                      use12Hours
                      inputReadOnly
                  />
                </div>
                <div className="d-flex flex-column">
                  <label><span className="mr-2 font-weight-bolder" id="primary_span">•</span>Language of 3rd {dayLabel} mass</label>
                  <Select placeholder="Select Language..." options={language_options} onChange={props.handlePageTwoChange.bind(this, 'lang', 'service3', dayVal)} /><br></br>
                </div>
                </form>
              </Card>
            )
        })} 
    </div>
    );
}