import React, { Component, Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { connect } from "react-redux"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import editTimeDataAttr from '../../actions/editTimeDataAttr'
import deleteTime from '../../actions/deleteTime'
import addNewTime from '../../actions/addNewTime'
import cloneDeep from 'lodash/cloneDeep'
import firebase from '../../config/fbConfig'
import {Redirect} from 'react-router-dom'
import {states} from '../../utils/states'
import {time_zone_options} from '../../utils/time_zone_options'
import {language_options} from '../../utils/language_options'
import { v4 as uuidv4 } from 'uuid'

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { StatusBullet } from 'components';
import ChurchTableCells from "../Dashboard/components/LatestOrders/ChurchTableCells";
import { handleViewLivestreamClick } from '../../actions/clickEvents';

const days = [
  {value: 'sunday', label:'Sunday'},
  {value: 'monday', label:'Monday'},
  {value: 'tuesday', label:'Tuesday'},
  {value: 'wednesday', label:'Wednesday'},
  {value: 'thursday', label:'Thursday'},
  {value: 'friday', label:'Friday'},
  {value: 'saturday', label:'Saturday'},
]

class ChurchStreamList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing:false,
      times: cloneDeep(this.props.churchtimes),
      timesToAdd: {},
      timesToDelete: {}
    }

    this.baseState = this.state

    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  toggleEdit = () => {
    this.setState({ editing : !this.state.editing} );
  }

  handleChange = (event, time_id) => {
    let newTime = this.state.times[time_id]
    newTime[event.target.name] = event.target.value
    if (event.target.name === 'military'){
      newTime.time = convertMilitaryToStandardTime(event.target.value)
    }
    this.setState({
      times: {
        ...this.state.times,
        [time_id]: newTime,
      }
    });
    
  };

  handleCancel = () => {
    // delete added times from state
    let resetTimes = {}
    for (let e in this.state.times){
      if (!(e in this.state.timesToAdd)){
        resetTimes[e] = this.state.times[e]
      }
    }

    for (let e in this.state.timesToDelete){
      resetTimes[e] = this.state.timesToDelete[e]
    }

    this.setState({times: {...resetTimes}, timesToAdd:{}, timesToDelete:{}})
    this.toggleEdit()
  }

  deleteTime = (time_id) => {
    let newTimes = {}
    let oldTime
    for (let id in this.state.times){
      if (id !== time_id){
        newTimes[id] = this.state.times[id]
      } else oldTime = this.state.times[id]
    }

    if (time_id in this.state.timesToAdd){
      let newTimesToAdd = {}
      for (let id in this.state.timesToAdd){
        if (id !== time_id){
          newTimesToAdd[id] = this.state.timesToAdd[id]
        }
      }
      this.setState({
        times: {
          ...newTimes
        },
        timesToAdd: {
          ...newTimesToAdd
        },
      });
    } else {
      this.setState({
        times: {
          ...newTimes
        },
        timesToDelete: {
          ...this.state.timesToDelete,
          [time_id]: oldTime
        }
      });
    }
  }

  createNewTimeRow = () => {
    let sample_time
    for (let key in this.props.churchtimes){
      sample_time = this.props.churchtimes[key]
      break
    }
    let church_id = sample_time.church_id
    let time_id = uuidv4()
    let time_obj = {
      language: 'English',
      time_id,
      church_id,
      day: 'sunday',
      time: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      livestream_link: '',
      time_zone: sample_time.time_zone,
      military: '',
      approved: false
    }
    this.setState({
      times: {
        ...this.state.times,
        [time_id]: time_obj,
      },
      timesToAdd: {
        ...this.state.timesToAdd,
        [time_id]: time_obj
      }
    });

  }

  handleSubmit = () => {
    try {
      // deletes times
      for (let e in this.state.timesToDelete){
        this.props.deleteTime(this.state.timesToDelete[e])
        // console.log(e)
        delete this.props.churchtimes[e]
      }

      // updates existing times
      for (let e in this.props.churchtimes){
        for (let key in this.state.times[e]){
          if (this.state.times[e][key] !== this.props.churchtimes[e][key]){
            this.props.editTimeDataAttr(e, key, this.state.times[e][key])
            this.props.churchtimes[e][key] = this.state.times[e][key]
          }
        }
      }

      // adds new times
      for (let e in this.state.timesToAdd){
        this.props.addNewTime(this.state.timesToAdd[e])
        this.props.churchtimes[e] = this.state.timesToAdd[e]
      }

      this.setState({
        timesToDelete: {},
        timesToAdd: {}
      });


      this.toggleEdit()
    } catch (error) {
      alert('There was an error in changing your details. Please try again later')
      console.log(error)
    }
    
  }

  render() {
    const { church } = this.props;
    const count = Object.keys(this.state.times).length;

    return (
      <Fragment>
        <Card
          id="church_table"
        >
          <CardHeader
            title={`${church.church_name} Live Stream Times`}
            subheader={`${count} stream${count===1?'':'s'} listed`}
          />
          {this.props.canEdit && !this.state.editing 
          ? 
            <IconButton color="primary" onClick={this.toggleEdit} aria-label="Edit Church Profile" component="span">
              <EditIcon />
            </IconButton>
          :
            <div>
              {this.state.editing ? 
                <div>
                  <Button
                  variant="contained"
                  onClick={this.handleCancel}
                  >cancel</Button>
                  <Button
                  color="secondary"
                  variant="contained"
                  onClick={this.handleSubmit}
                  >save</Button>
                </div>
              : null}
            </div>
          }
          <Divider />
          <CardContent className="tablecontent">
            <PerfectScrollbar>
              <div className="tableinner">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Link</TableCell>
                      <TableCell>Day</TableCell>
                      <TableCell id="semiwide-column">
                        <div className="table_header_inner" onClick={this.handleTimeToggle}>
                          Time
                        </div>
                      </TableCell>
                      <TableCell className="timezone_column">
                        <div className="table_header_inner" onClick={this.handleTimezoneToggle}>
                          Timezone
                        </div>
                      </TableCell>
                      <TableCell className="language_column">
                        <div className="table_header_inner" onClick={this.handleLanguageToggle}>
                          Language of Service
                        </div>
                      </TableCell>
                      {this.state.editing ? 
                        <TableCell className="language_column">
                        <div className="table_header_inner" onClick={this.handleLanguageToggle}>
                          Link Approved
                        </div>
                      </TableCell>
                      :
                      null
                      }
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { this.state.editing ? 
                      Object.keys(this.state.times).map(id => (
                          <TableRow
                            hover
                            key={this.state.times[id].time_id}
                          >
                            <TableCell>
                              <TextField 
                                margin="dense"
                                name="livestream_link"
                                required
                                value={this.state.times[id].livestream_link}
                                onChange={(e)=>{this.handleChange(e, id)}}
                                ></TextField>
                            </TableCell>
                            <TableCell>
                              <TextField
                                  margin="dense"
                                  name="day"
                                  onChange={(e)=>{this.handleChange(e, id)}}
                                  select
                                  SelectProps={{ native: true }}
                                  value={this.state.times[id].day}
                                >
                                  {days.map(option => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </TextField>
                            </TableCell>
                            <TableCell>
                              <TextField
                                  margin="dense"
                                  name="military"
                                  type='time'
                                  onChange={(e)=>{this.handleChange(e, id)}}
                                  defaultValue={this.state.times[id].military}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    step: 300, // 5 min
                                  }}
                                >
                                </TextField>
                            </TableCell>
                            <TableCell>
                              <TextField
                                margin="dense"
                                name="time_zone"
                                onChange={(e)=>{this.handleChange(e, id)}}
                                select
                                SelectProps={{ native: true }}
                                value={this.state.times[id].time_zone}
                              >
                                {time_zone_options.map(option => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </TextField>
                            </TableCell>
                            <TableCell>
                              <TextField
                                  margin="dense"
                                  name="language"
                                  onChange={(e)=>{this.handleChange(e, id)}}
                                  select
                                  SelectProps={{ native: true }}
                                  value={this.state.times[id].language}
                                >
                                  {language_options.map(option => (
                                    <option
                                      key={option.value}
                                      value={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </TextField>
                            </TableCell>
                            <TableCell>
                              {this.state.times[id].approved ? 
                              <h6 style={{color:'green'}}>Yes</h6>
                              : 
                              <h6 style={{color:'red'}}>Not Yet</h6>}
                            </TableCell>
                            <TableCell>
                            <IconButton color="secondary" onClick={()=>{this.deleteTime(id)}} aria-label="Edit Church Profile" component="span">
                              <DeleteIcon />
                            </IconButton>

                            </TableCell>
                            
                          </TableRow>
                        ))
                    :
                      Object.keys(this.state.times).map(id => (
                          <TableRow
                            hover
                            key={this.state.times[id].time_id}
                          >
                            <TableCell>
                              <div className="tablestatusContainer">
                                <a target="_blank" href={this.state.times[id].livestream_link}>Live Stream</a>
                              </div>
                            </TableCell>
                            <TableCell>{this.state.times[id].day.charAt(0).toUpperCase() + this.state.times[id].day.slice(1)}</TableCell>
                            <TableCell>{this.state.times[id].time}</TableCell>
                            <TableCell>{this.state.times[id].time_zone}</TableCell>
                            <TableCell>{this.state.times[id].language}</TableCell>
                          </TableRow>
                        ))
                    }
                  </TableBody>
                </Table>
                {this.state.editing ? 
                  <IconButton color="secondary" aria-label="Add Time" onClick={this.createNewTimeRow} component="span">
                      Add New Time<AddIcon />
                   </IconButton>
                   :
                   null
                }
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>
      </Fragment>
    )
  };
};

function convertMilitaryToStandardTime(military){
  let hours = military.split(':')[0]
  let mins = military.split(':')[1]
  let ampm
  if (hours < 12) {
    ampm = 'am'
    if (hours[0]==0){
      hours = hours[1]
    }
  }
  else { 
    ampm = 'pm'
    hours -= 12
  }
  if (hours == 0){
    hours = 12
  }
  return hours + ':' + mins + ' ' + ampm
}


function mapStateToProps({ allTimes }, props) {
  const { times } = props.church;
  var time_dict = {}
  times.map(time => allTimes[time]).forEach((time)=>{
    time_dict[time.time_id] = time
  })
  return {
    churchtimes: time_dict
  }
}

const mapDispatchToProps = dispatch => {
  return {
      addNewTime: (time_obj) => dispatch(addNewTime(time_obj)),
      deleteTime: (time_obj) => dispatch(deleteTime(time_obj)),
      editTimeDataAttr: (time_id, attribute, new_val) => dispatch(editTimeDataAttr(time_id, attribute, new_val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChurchStreamList);

