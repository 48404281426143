import { SET_ALL_TIMES } from "../actions/allTimes";

export default function allTimes(state = {}, action) {

  switch(action.type) {
    case SET_ALL_TIMES:
      return action.times;
    default:
      return state;
  }
}
