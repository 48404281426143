import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';


class Zipcode extends Component {

  state = {
    zipcode: ''
  };

  handleChange = (event) => {
    this.setState(
      { zipcode: event.target.value },
    )};

  render()  {
    const { zipcode } = this.state;
    const { className, ...rest } = this.props;

    return (
      <Fragment>
        {/*<TextField id="standard-basic" label="Standard" />*/}
        <Card
        {...rest}
        className={clsx(className)}
        id="zip_card"
        >
        <form>
          {/*<CardHeader*/}
          {/*subheader="Enter your zip code to find local church streams"*/}
          {/*title="Search by Zip Code"*/}
          {/*/>*/}
          {/*<Divider />*/}
          <CardContent>
          {/*<TextField*/}
            {/*fullWidth*/}
            {/*label="Enter Zip Code"*/}
            {/*name="zipcode"*/}
            {/*onChange={this.handleChange}*/}
            {/*value={zipcode}*/}
            {/*variant="outlined"*/}
          {/*/>*/}
          <TextField
            fullWidth
            label="Enter Zip Code"
            id="outlined-basic"
            // onChange={this.handleChange}
            onChange={event => this.handleChange(event)}
            value={zipcode}
            // value={zipcode}
            variant="outlined" />
          </CardContent>
          {/*<Divider />*/}
          {/*<CardActions>*/}
          {/*<Button*/}
          {/*color="primary"*/}
          {/*variant="outlined"*/}
          {/*>*/}
          {/*Search*/}
          {/*</Button>*/}
          {/*</CardActions>*/}
        </form>
        </Card>
      </Fragment>
    );
  }
};

Zipcode.propTypes = {
  className: PropTypes.string
};

export default Zipcode;
