export const editTimeDataAttr = (time_id, attribute, new_val) => {
    return (dispatch, getState, {getFirestore}) => {
      const firestore = getFirestore();
      //   const profile = getState().firebase.profile;
      //   const authorId = getState().firebase.auth.uid;
  
      firestore.collection('time_data').doc(time_id).update(
          {[attribute]: new_val}
      )
        .then(()=>{
          firestore.collection('time_data').doc(time_id).update(
            {updatedAt: new Date()}
        )
        })
          .then(() => {
            dispatch({ type: 'EDIT_TIME_DATA_ATTR_SUCCESS' });
          }).catch(err => {
          dispatch({ type: 'EDIT_TIME_DATA_ATTR_ERROR' }, err);
        });
    }
  };
  
  export default editTimeDataAttr
  