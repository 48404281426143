import { getDayTimeDataAsDict } from '../utils/fb_api';
import { setAppLoaded } from "./appLoaded";
import { handleGetChurchTimesByDay } from "./churchtimes";

export const SET_DAY_TIMES = "SET_DAY_TIMES";

export function handleSetDayTimes() {
  // getDayTimeDataAsDict().then(t => console.log(t))
  return (dispatch, getState) => {
    getDayTimeDataAsDict()
      .then(dayTimes => dispatch(setDayTimes(dayTimes)))
      .then(() => dispatch(setAppLoaded(true)))
      .then(() => dispatch(handleGetChurchTimesByDay("sunday", "")))
  }
}

function setDayTimes(dayTimes) {
  return {
    type: SET_DAY_TIMES,
    dayTimes
  }
}

