import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

var firebaseConfig = {
    apiKey: "AIzaSyA_GZB4-x4-lWLts32qFBjTjl3r5vwUOwU",
    authDomain: "withyourspirit-org.firebaseapp.com",
    databaseURL: "https://withyourspirit-org.firebaseio.com",
    projectId: "withyourspirit--org",
    storageBucket: "withyourspirit--org.appspot.com",
    messagingSenderId: "152585320399",
    appId: "1:152585320399:web:9516f127d661ddcf5e44ba"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({timestampsInSnapshots: true});

export default firebase;