import React, { Component, Fragment } from 'react'
import { Card } from '@material-ui/core';
import Select from 'react-select'
import {diocese_options} from '../../utils/diocese_options'
import {states} from '../../utils/states'
import {time_zone_options} from '../../utils/time_zone_options'

export default function Step1(props) {
    if (props.state.currentStep !== 1) {
        return null;
    }

    return(
        <Fragment>
        <div className="form-group form_inner">
            <Card className="form_card">
            <label htmlFor="church_name">Name of Parish, Chapel, University, Newman Center</label>
            <input required value={props.state.church_name} type="text" id='church_name' onChange={props.handleChange} />
            </Card>

            <Card className="form_card">
            <label>Please select your Diocese</label>
            <Select required placeholder={props.state.diocese} options={diocese_options} onChange={props.handleSelectChange.bind(this, 'diocese')} />
            </Card>

            <Card className="form_card">
            <label>What is your time zone?</label>
            <Select required placeholder={props.state.time_zone} options={time_zone_options} onChange={props.handleSelectChange.bind(this, 'time_zone')} />
            </Card>

            <Card className="form_card">
            <label>State</label>
            <Select required placeholder={props.state.state} options={states} onChange={props.handleSelectChange.bind(this, 'state')} />
            </Card>

            <Card className="form_card">
            <label htmlFor="city">City (e.g., "Pittsburgh")</label>
            <input required value={props.state.city} type="text" id='city' onChange={props.handleChange} />
            </Card>

            <Card className="form_card">
            <label htmlFor="address">Street Address (e.g., "123 Main Street")</label>
            <input required value={props.state.address} type="text" id='address' onChange={props.handleChange} />
            </Card>

            <Card className="form_card">
            <label htmlFor='zipcode'>ZIP Code</label>
            <input required value={props.state.zipcode} type="text" id='zipcode' onChange={props.handleChange} />
            </Card>

            <Card className="form_card">
            <label htmlFor='pastor_name'>Name of Pastor</label>
            <input required value={props.state.pastor_name} type="text" id='pastor_name' onChange={props.handleChange}/>
            </Card>

            <Card className="form_card">
            <label htmlFor='days'>Days that livestreams are offered<br/><span id="primary_span">Select all days that apply</span></label>
            <Select placeholder='(can select multiple)'required defaultValue={props.state.days} required id='days' isMulti options={days} onChange={props.handleSelectChange.bind(this, 'days')} />
            </Card>
        </div>
        </Fragment>
        );
}

const days = [
    {value: 'sunday', label:'Sunday'},
    {value: 'monday', label:'Monday'},
    {value: 'tuesday', label:'Tuesday'},
    {value: 'wednesday', label:'Wednesday'},
    {value: 'thursday', label:'Thursday'},
    {value: 'friday', label:'Friday'},
    {value: 'saturday', label:'Saturday'},
]

